<template>
  <svg
    :class="classList ? classList : 'w-6 h-6'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m17.679,10.013c-.013-.015-3.337-3.818-3.334-3.806-.54-.751-1.484-1.224-2.511-1.224-.028,0-.055.003-.082.004-.856.022-1.553.463-2.085,1.031l3.701,4.182h0s-6.159,3.855-6.159,3.855c0,0-1.168.856-2.495.541.109.068,3.474,2.169,3.474,2.169.499.313,1.146.313,1.645,0,0,0,7.339-4.596,7.489-4.687.826-.501.838-1.512.357-2.066Z"
    />
    <path
      d="m10.113,1.467c-.525-.577-1.598-.683-2.248.043-.189.211-7.325,8.262-7.526,8.489-.568.638-.403,1.615.353,2.081,0,0,1.751,1.097,1.865,1.169.696.436,2.571,1.036,4.454-.096l1.179-.738-3.536-2.213s4.461-5.039,4.467-5.047c1.294-1.416,2.987-1.032,3.613-.735.002.001-2.607-2.937-2.621-2.951Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'AzureEntraIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
