<template>
  <div ref="outerDiv" :class="fullscreen ? 'relative bg-white dark:bg-gray-900 p-4' : ''">
    <div
      class="w-full relative bg-white dark:bg-gray-900 rounded-md text-[13px] overflow-x-auto overflow-y-visible"
      style="font-family: 'Inter', monospace; font-variant-numeric: tabular-nums"
      v-if="paginatedTableData.length > 0"
      :class="{
        'pb-[40px] sm:pb-[28px]': tableData.length > 10,
      }"
    >
      <!-- Loader -->
      <div
        class="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.5)] dark:bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-[10]"
        v-if="loading"
      >
        <LoadingIcon classList="w-6 h-6 text-gray-500 dark:text-gray-400 animate-spin" />
      </div>

      <!-- Fullscreen button -->
      <div class="top-0 right-0 absolute flex flex-col" :class="'m-1'">
        <button
          v-tooltip="!this.fullscreen ? 'Full Screen' : 'Exit Full Screen'"
          style="z-index: 6"
          class="opacity-60 bg-opacity-10 text-black dark:text-white hover:opacity-100 rounded-full p-1 hover:bg-blend-soft-light"
          @click="toggleFullscreen"
        >
          <svg
            v-if="!this.fullscreen"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Pagination controls -->
      <div
        class="absolute bottom-0 text-[12px] text-gray-500 dark:text-gray-300 w-full border-t border-gray-200 dark:border-gray-800 overflow-y-visible"
        v-if="tableData.length > 10"
      >
        <div class="py-2 sm:py-1 flex gap-3 items-center sm:justify-end w-full px-[12px]">
          <div class="flex items-center gap-2 shrink-0 w-max">
            <button
              v-tooltip="'First page'"
              @click="gotoFirstPage"
              :disabled="currentPage === 1"
              class="cursor-pointer disabled:text-gray-200 disabled:dark:text-gray-600 disabled:cursor-not-allowed"
            >
              <AnglePipeLeftIcon classList="w-4 h-4" />
            </button>
            <button
              v-tooltip="'Previous page'"
              @click="prevPage"
              :disabled="currentPage === 1"
              class="cursor-pointer disabled:text-gray-200 disabled:dark:text-gray-600 disabled:cursor-not-allowed"
            >
              <AngleLeftIcon classList="w-4 h-4" />
            </button>
          </div>

          <span class="mx-2 w-max shrink-0">Page {{ currentPage }} of {{ totalPages }}</span>

          <div class="flex items-center gap-2 shrink-0 w-max">
            <button
              v-tooltip="'Next page'"
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="cursor-pointer disabled:text-gray-200 disabled:dark:text-gray-600 disabled:cursor-not-allowed"
            >
              <AngleRightIcon classList="w-4 h-4" />
            </button>
            <button
              v-tooltip="'Last page'"
              @click="gotoLastPage"
              :disabled="currentPage === totalPages"
              class="cursor-pointer disabled:text-gray-200 disabled:dark:text-gray-600 disabled:cursor-not-allowed"
            >
              <AnglePipeRightIcon classList="w-4 h-4" />
            </button>
          </div>

          <div class="flex gap-2 ml-2 w-max shrink-0">
            <span>Rows per page:</span>
            <div class="relative z-10" ref="options">
              <span
                class="flex items-center w-max gap-2 bg-gray-100 dark:bg-gray-800 px-4 cursor-pointer rounded"
                @click.stop="showOptions = !showOptions"
              >
                {{ itemsPerPage }}
              </span>
              <div
                v-if="showOptions"
                class="absolute bottom-6 right-0 w-max bg-white dark:bg-gray-800 shadow-md rounded-md overflow-hidden z-10"
              >
                <div
                  v-for="item in itemsPerPageOptions"
                  :key="item"
                  class="w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                  @click="(itemsPerPage = item), (showOptions = false)"
                >
                  {{ item }}
                </div>
                <div
                  class="w-full py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                  @click="(itemsPerPage = tableData.length), (showOptions = false)"
                >
                  All
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full overflow-auto h-auto" :class="!fullscreen ? 'max-h-[600px]' : 'custom-max-h'">
        <table class="min-w-full w-max rounded-xl table-fixed" ref="tableContainer">
          <thead class="thead-sticky sticky -top-[1px] z-[5] bg-white dark:bg-gray-900">
            <tr class="">
              <th
                class="w-8 max-w-[40px] p-2 text-left border-r border-gray-200 dark:border-gray-800 text-gray-400 dark:text-gray-500 font-normal opacity-0"
                v-if="tableHeaders.length > 0"
              ></th>
              <th
                class="break-words p-2 text-left border-r last:border-r-0 border-gray-200 dark:border-gray-800 text-xs font-semibold relative w-auto"
                v-for="(header, index) in tableHeaders"
                :key="header"
                @click="sortData(header)"
                v-tooltip="`Sort by ${header}`"
                :ref="`header-${index}`"
              >
                <span class="flex items-center gap-1 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ header }}
                  <LongArrowDownIcon v-if="sortKey === header && sortDirection === 'desc'" classList="w-4 h-3" />
                  <LongArrowUpIcon v-if="sortKey === header && sortDirection === 'asc'" classList="w-4 h-3" />
                </span>
                <div
                  class="w-[5px] h-full absolute right-0 top-0 cursor-col-resize"
                  @mousedown="startResize($event, index)"
                  @click.stop
                ></div>
              </th>
            </tr>
            <div class="w-full border-b border-gray-200 dark:border-gray-800"></div>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in paginatedTableData"
              :key="generateUniqueKey(index)"
              class="hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              <td
                v-if="tableHeaders.length > 0"
                class="border-r border-gray-200 dark:border-gray-800 text-gray-400 dark:text-gray-500"
              >
                <div class="px-2 text-right max-w-[40px]">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</div>
              </td>
              <td
                class="text-left border-x last:border-r-0 border-gray-200 dark:border-gray-800 relative w-max max-w-[300px]"
                v-for="(value, colIndex) in Object.values(row)"
                :key="`${value}${index}`"
              >
                <!-- cell value, align right when it represents a number -->
                <span
                  class="overflow-hidden block px-2 py-1 text-ellipsis whitespace-nowrap w-full"
                  :class="Number.isFinite(Number(value)) ? 'text-right' : ''"
                  v-tooltip="value.text"
                  v-dompurify-html="value.html"
                ></span>
                <div
                  class="w-[5px] h-full absolute right-0 top-0 cursor-col-resize"
                  @mousedown="startResize($event, colIndex, false)"
                  @click.stop
                ></div>
              </td>
            </tr>
            <tr v-if="hasMoreThan5kRows && currentPage === totalPages" class="hover:bg-gray-100 dark:hover:bg-gray-800">
              <td
                v-if="tableHeaders.length > 0"
                class="border-r px-2 text-right border-gray-200 dark:border-gray-800 text-gray-400 dark:text-gray-500 max-w-[40px]"
              >
                {{ tableData.length + 1 }}
              </td>
              <td
                :colspan="tableHeaders.length"
                class="text-left border border-gray-200 dark:border-gray-800 px-2 py-1"
              >
                <span class="overflow-hidden block px-2 py-1 text-ellipsis whitespace-nowrap w-full">
                  Download the full csv to view all rows...
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import * as cheerio from 'cheerio'
  import { v4 as uuid } from 'uuid'
  import AngleRightIcon from './icons/AngleRightIcon.vue'
  import AngleLeftIcon from './icons/AngleLeftIcon.vue'
  import AnglePipeRightIcon from './icons/AnglePipeRightIcon.vue'
  import AnglePipeLeftIcon from './icons/AnglePipeLeftIcon.vue'
  import LongArrowDownIcon from './icons/LongArrowDownIcon.vue'
  import LongArrowUpIcon from './icons/LongArrowUpIcon.vue'
  import LoadingIcon from './icons/LoadingIcon.vue'

  export default {
    name: 'DataTable',
    components: {
      AngleRightIcon,
      AngleLeftIcon,
      AnglePipeRightIcon,
      AnglePipeLeftIcon,
      LongArrowDownIcon,
      LongArrowUpIcon,
      LoadingIcon,
    },
    props: {
      tableDataString: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        tableData: [],
        tableHeaders: [],
        currentPage: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [2, 10, 50, 100, 1000],
        showOptions: false,
        sortKey: '',
        sortDirection: 'asc',
        paginatedTableData: [],
        loading: false,
        fullscreen: false,
        table: null,
        columnWidths: [],
        resizeIndex: null,
        resizing: false,
        initialX: 0,
        initialWidth: 0,
        resizer: null,
        hasMoreThan5kRows: false,
      }
    },
    watch: {
      tableDataString: {
        immediate: true,
        handler(newData) {
          this.parseTableData(newData)
        },
      },
      sortKey: {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) this.sortAndSliceData()
        },
        immediate: true,
      },
      sortDirection: {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) this.sortAndSliceData()
        },
        immediate: true,
      },
      // Watch for changes to the current page or items per page
      currentPage: {
        handler() {
          this.sliceData()
        },
        immediate: true,
      },
      itemsPerPage: {
        handler() {
          this.gotoFirstPage()
          this.sliceData()
        },
        immediate: true,
      },
    },
    computed: {
      totalPages() {
        return Math.ceil(this.tableData.length / this.itemsPerPage)
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      parseTableData(tableDataString) {
        const allRows = tableDataString.match(/<tr[^>]*>(.*?)<\/tr>/gs)
        this.hasMoreThan5kRows = allRows.length >= 5000

        const $ = cheerio.load(tableDataString)

        // Extract table headers
        this.tableHeaders = $('thead tr th')
          .map((_, el) => $(el).text().trim())
          .get()
          .filter(header => header !== '') // Filter out empty header (S/N column)

        // Set initial column widths
        this.columnWidths = this.tableHeaders.map(() => (this.tableHeaders.length > 3 ? '200px' : 'auto'))

        // Extract table rows
        let rows = $('tbody tr')

        // if has more than 5k rows, remove the last row
        if (this.hasMoreThan5kRows) {
          rows = rows.slice(0, -1)
        }

        this.tableData = rows
          .map((_, row) => {
            const rowData = $(row)
              .find('td')
              .map((_, el) => ({
                html: $(el).html().trim(),
                text: $(el).text().trim(),
              }))
              .get()
            return this.tableHeaders.reduce((acc, header, index) => {
              return { ...acc, [header]: rowData[index] }
            }, {})
          })
          .get()
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--
        }
      },
      gotoFirstPage() {
        this.currentPage = 1
      },
      gotoLastPage() {
        this.currentPage = this.totalPages
      },
      // Generate a unique key for each row
      generateUniqueKey(index) {
        const uniqueKey = uuid()
        return `${uniqueKey}${index}`
      },

      handleClickOutside(e) {
        if (!this.$refs?.options?.contains(e.target) && this.$refs?.options !== e.target) {
          this.showOptions = false
        }
      },
      sortData(header) {
        // If the same column header is clicked again, toggle the sorting direction
        if (header === this.sortKey) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortKey = header
          this.sortDirection = 'asc'
        }
      },
      async sortAndSliceData() {
        try {
          this.loading = true
          if (this.sortKey) {
            // sort the data here
            this.tableData.sort((a, b) => {
              const aValue = a[this.sortKey].text || a[this.sortKey].html || a[this.sortKey] || ''
              const bValue = b[this.sortKey].text || b[this.sortKey].html || b[this.sortKey] || ''

              // Explicitly check if the value is a date in 'yyyy-mm-dd' format
              const dateRegex = /^\d{4}-\d{2}-\d{2}$/
              const isDateA = dateRegex.test(aValue)
              const isDateB = dateRegex.test(bValue)

              const dateA = new Date(aValue)
              const dateB = new Date(bValue)

              const numericA = Number(aValue)
              const numericB = Number(bValue)

              if (isDateA && isDateB) {
                // If both values are valid dates, compare them
                return this.sortDirection === 'asc' ? dateA - dateB : dateB - dateA
              } else if (!isNaN(numericA) && !isNaN(numericB) && !isDateA && !isDateB) {
                // If both values are numbers, compare them numerically
                return this.sortDirection === 'asc' ? numericA - numericB : numericB - numericA
              } else {
                // Otherwise, compare them as strings
                return this.sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
              }
            })
          }
          this.sliceData()
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },

      async sliceData() {
        try {
          this.loading = true
          const startIndex = (this.currentPage - 1) * this.itemsPerPage
          const endIndex = startIndex + this.itemsPerPage

          this.paginatedTableData = this.tableData.slice(startIndex, endIndex)
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      toggleFullscreen() {
        this.fullscreen = !this.fullscreen
        if (this.fullscreen) {
          // request fullscreen and when this is done, render the chart
          this.$refs.outerDiv.requestFullscreen().then(() => {
            // pass
          })
        } else {
          // exit fullscreen
          document.exitFullscreen().then(() => {
            // pass
          })
        }
      },
      startResize(event, index, isHeader = true) {
        this.resizing = true
        this.resizeIndex = index
        // Prevent text selection during resizing
        document.body.style.userSelect = 'none'
        // Get a reference to the <th> element
        const thElement = isHeader ? event.target.closest('th') : event.target.closest('td')
        // Capture the initial mouse position and column width
        this.initialX = event.clientX
        // Use getComputedStyle to get the actual calculated width
        this.initialWidth = parseFloat(window.getComputedStyle(thElement).width)
        // Add event listeners for resizing logic
        window.addEventListener('mousemove', this.handleResize)
        window.addEventListener('mouseup', this.stopResize)
      },
      handleResize(event) {
        if (!this.resizing) return
        const delta = event.clientX - this.initialX
        let newWidth = this.initialWidth + delta
        // Optional: Set minimum and/or maximum column widths
        newWidth = Math.max(newWidth, 50) // Example: Minimum width of 50px
        if (this.$refs[`header-${this.resizeIndex}`]) {
          this.$refs[`header-${this.resizeIndex}`][0].style.width = `${newWidth}px`
        }
      },
      stopResize() {
        this.resizing = false
        this.resizeIndex = null
        // Restore text selection ability
        document.body.style.userSelect = ''
        // Remove event listeners
        window.removeEventListener('mousemove', this.handleResize)
        window.removeEventListener('mouseup', this.stopResize)
      },
    },
  }
</script>

<style scoped>
  .thead-sticky::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgb(235, 235, 235); /* border-gray-200 */
  }

  .dark .thead-sticky::after {
    border-color: rgb(56, 56, 56); /* border-gray-800 */
  }

  .custom-max-h {
    max-height: calc(100vh - 48px); /* 100vh for full viewport height minus 32px for padding */
  }
</style>
