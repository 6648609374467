<template>
  <div
    class="grow w-96 lg:max-w-[24rem] items-start border rounded-xl"
    :class="{
      'self-start': !isExpanded,
      'p-4': isExpanded,
      'border-blue-600 dark:border-blue-500 dark:border-opacity-80': connectionIsActive,
      'border-gray-100 dark:border-gray-800': !connectionIsActive,
    }"
    @dragenter.prevent="
      () => {
        if (connectionName === 'File Upload') {
          isExpanded = true
        }
      }
    "
  >
    <div
      :id="connectionName"
      class="flex justify-between items-center cursor-pointer"
      :class="{
        'pb-5': isExpanded,
        'p-4': !isExpanded,
      }"
      @click="toggleExpand"
    >
      <div class="flex items-center gap-3">
        <div
          class="text-sm font-mono"
          :class="connectionIsActive ? 'text-blue-800 dark:text-blue-300' : 'text-gray-500 dark:text-gray-400'"
        >
          {{ connectionName }}
        </div>
      </div>
      <div class="flex items-center gap-2">
        <slot name="icon"></slot>
        <AngleDownIcon classList="ml-auto w-5 h-5 text-gray-500 dark:text-gray-400" v-if="!isExpanded" />
        <AngleUpIcon classList="ml-auto w-5 h-5 text-gray-500 dark:text-gray-400" v-if="isExpanded" />
      </div>
    </div>
    <div :class="{ 'max-h-0 overflow-hidden': !isExpanded }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import AngleDownIcon from '@/components/icons/AngleDownIcon.vue'
  import AngleUpIcon from '@/components/icons/AngleUpIcon.vue'
  export default {
    name: 'ConnectionWrapper',
    components: {
      AngleDownIcon,
      AngleUpIcon,
    },
    props: {
      connectionName: {
        type: String,
        required: true,
      },
      connectionIsActive: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        isExpanded: false,
      }
    },

    methods: {
      toggleExpand() {
        this.isExpanded = !this.isExpanded
      },
    },
  }
</script>

<style scoped>
  .slow-ping {
    animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
</style>
