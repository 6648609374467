<template>
  <div
    class="px-[24px] lg:px-12 pb-12 overflow-y-auto bg-white text-black dark:text-white dark:bg-gray-950 w-full max-w-[1920px] mx-auto"
  >
    <div class="w-full flex py-4 mb-4 sm:items-center justify-between flex-col sm:flex-row gap-4 sm:gap-0">
      <h1 class="text-2xl font-bold text-left">History</h1>
    </div>
    <div class="w-full">
      <div class="w-full flex items-center">
        <h2 class="pl-6 text-left">Messages · {{ totalMessagesLength }}</h2>
        <div class="items-center sm:ml-auto">
          <select
            id="month"
            v-model="selectedYearMonth"
            class="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-2 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option v-for="month in availableMonths" :value="month" :key="month">
              {{ formatDate(month + '-01') }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-full overflow-x-auto">
        <apexchart
          type="bar"
          height="200"
          :options="chartOptions"
          :series="series"
          class="w-full min-w-[620px]"
        ></apexchart>
      </div>
    </div>

    <!-- History Table -->
    <div v-if="!this.chats?.length" class="mt-[10%]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="0.5"
        stroke="currentColor"
        class="w-12 h-12 mx-auto"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
        />
      </svg>
      <h1 class="text-center mt-4">Nothing to remember, yet.</h1>
    </div>
    <div class="relative overflow-x-auto border dark:border-gray-900 sm:rounded-xl mt-10" v-else>
      <div class="flex items-center justify-between dark:bg-gray-900">
        <div class="bg-white dark:bg-gray-950">
          <label for="table-search" class="sr-only">Search</label>
          <div class="flex flex-col sm:flex-row sm:justify-between">
            <div class="relative pl-3">
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400 absolute pointer-events-none top-1/2 left-3 -translate-y-1/2"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <input
                type="text"
                id="table-search"
                v-model="search_input"
                class="block p-2 pl-6 text-sm text-gray-900 border-0 rounded-xl w-80 focus:ring-0 focus:outline-none focus:dark:text-white dark:bg-gray-950 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search.."
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between gap-1 w-max shrink-0">
          <button
            v-for="filter in filters"
            :key="filter.value"
            @click="changeFilter(filter.value)"
            :class="`px-2.5 py-1 text-gray-500 text-xs font-medium text-center rounded-full focus:ring-4 focus:outline-none  focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600 ${
              current_filter === filter.value
                ? 'bg-gray-100 dark:bg-gray-700 dark:text-white text-gray-900'
                : 'bg-white dark:text-gray-400 dark:bg-gray-900'
            }`"
          >
            {{ filter.name }}
          </button>
        </div>
      </div>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead
          class="text-xs text-gray-700 uppercase border-t border-b border-gray-100 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center">First Question</div>
            </th>
            <th scope="col" class="px-6 py-3">User</th>
            <th scope="col" class="px-6 py-3">Date</th>
            <th scope="col" class="px-2 py-3 text-center">Messages</th>
            <th scope="col" class="px-2 py-3 text-center">Feedback</th>
            <th scope="col" class="px-2 py-3 text-center">Scheduled</th>
            <th scope="col" class="px-2 py-3 text-center">Shared</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredItems"
            :key="item.id"
            class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
            @click="this.$router.push({ path: '/', query: { c: item.id } })"
          >
            <td alt="item.name" scope="row" class="max-w-xl">
              <router-link
                :to="{ path: '/', query: { c: item.id } }"
                class="pl-6 pr-2 py-4 text-xs block truncate font-medium text-gray-900 whitespace-nowrap dark:text-white w-full"
              >
                {{ item.first_question }}
              </router-link>
            </td>
            <td v-if="item.user_id" class="max-w-xs">
              <router-link
                :to="{ path: '/', query: { c: item.id } }"
                class="pl-6 pr-2 py-4 text-xs truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400 block w-full"
              >
                {{ item.user_id }}
              </router-link>
            </td>
            <td
              class="max-w-xs"
              v-tooltip="{
                content: `<small>${new Date(item.date).toLocaleString()}</small>`,
                html: true,
              }"
            >
              <router-link
                :to="{ path: '/', query: { c: item.id } }"
                class="pl-6 pr-2 py-4 text-xs block w-full truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
              >
                {{ getTimeAgo(item.date) }}
              </router-link>
            </td>
            <td class="max-w-xs">
              <router-link
                :to="{ path: '/', query: { c: item.id } }"
                class="px-2 py-4 text-xs block w-full truncate text-ellipsis font-medium text-gray-500 text-center dark:text-gray-400"
              >
                {{ getMessageNumber(item.messages_length + (item.edits_length || 0)) }}
              </router-link>
            </td>
            <td class="max-w-xs">
              <router-link
                :to="{ path: '/', query: { c: item.id } }"
                class="px-1 py-2 text-xs block w-full truncate text-ellipsis font-medium text-center"
              >
                <span
                  class="rounded-full px-2 py-1 bg-opacity-20 dark:bg-opacity-10"
                  :class="
                    item.overall_feedback > 0
                      ? 'text-green-700 dark:text-green-300 bg-green-400 dark:bg-green-300'
                      : item.overall_feedback < 0
                        ? 'text-red-700 dark:text-red-300 bg-red-400 dark:bg-red-300'
                        : 'text-gray-500 dark:text-gray-400'
                  "
                >
                  {{ item.overall_feedback > 0 ? 'Success' : item.overall_feedback < 0 ? 'Problem' : '-' }}
                </span>
              </router-link>
            </td>

            <td class="max-w-xs p-1 text-xs font-medium text-gray-500 text-center dark:text-gray-400">
              <div @click.stop class="relative">
                <button
                  v-if="item.schedules && JSON.parse(item.schedules)?.length > 0"
                  @click="$refs[`scheduled-link-${item.id}`][0].classList.toggle('hidden')"
                  class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                </button>

                <div
                  :ref="`scheduled-link-${item.id}`"
                  class="z-10 hidden absolute right-0 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700"
                >
                  <ul
                    class="text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        @click.stop="
                          deleteSchedule(item), $refs[`scheduled-link-${item.id}`][0].classList.toggle('hidden')
                        "
                        class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Delete Schedule
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </td>

            <td class="max-w-xs p-1 text-xs block w-full font-medium text-gray-500 text-center dark:text-gray-400">
              <!-- Dropdown button -->
              <div @click.stop class="relative">
                <button
                  v-if="item.public"
                  @click="$refs[`shared-link-${item.id}`][0].classList.toggle('hidden')"
                  class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.0"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                </button>

                <!-- Dropdown menu -->
                <div
                  :ref="`shared-link-${item.id}`"
                  class="z-10 hidden absolute right-0 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700"
                >
                  <ul
                    class="text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        @click.stop="
                          get_shared_link(item.id), $refs[`shared-link-${item.id}`][0].classList.toggle('hidden')
                        "
                        class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Copy Link
                      </button>
                    </li>
                    <li>
                      <button
                        @click.stop="
                          delete_shared_link(item), $refs[`shared-link-${item.id}`][0].classList.toggle('hidden')
                        "
                        class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Delete Link
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table for data end -->

    <div class="flex-grow"></div>
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { storeToRefs } from 'pinia'
  import { format, getMonth, getYear, isSameMonth, parseISO, formatDistanceToNow } from 'date-fns'
  import { useGlobalStore } from './stores/globalStore'

  export default {
    name: 'HistoryPage',
    setup() {
      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)
      console.log(userStore.isDarkMode)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      return {
        userStore,
        user,
        notify,
      }
    },
    mounted() {
      document.title = 'History · Dot'
    },
    data() {
      return {
        chats: [{ first_question: 'Loading..' }],
        search_input: '',
        // initate selectedYearMonth as YYYY-MM for current month
        selectedYearMonth: format(new Date(), 'yyyy-MM'),
        message: '',
        isSuccess: false,
        chartOptions: {
          theme: {
            monochrome: {
              enabled: true,
              color: '#FFA500', // Font color
              shadeTo: 'light',
              shadeIntensity: 0.65,
            },
          },
          grid: {
            strokeDashArray: [1, 5], // Solid lines
            borderColor: 'rgba(164,164,164,0.5)', // 60% opacity
          },
          chart: {
            id: 'vuechart-example',
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            theme: 'dark',
          },
          dataLabels: {
            enabled: false,
          },

          xaxis: {
            labels: {
              formatter: value => {
                try {
                  return format(parseISO(value), 'dd MMM')
                } catch (error) {
                  return value
                }
              },
              style: {
                colors: '#969696', // Font color for X-axis labels
              },
            },
            tickAmount: 10, // Number of ticks on x-axis
          },
          yaxis: {
            labels: {
              style: {
                colors: '#969696', // Font color for Y-axis labels
              },
              formatter: value => {
                // Ensure the value is a whole integer
                return Math.round(value)
              },
            },
          },
        },
        series: [],
        current_filter: 'all',
        filters: [
          { name: 'All', value: 'all' },
          { name: 'Scheduled', value: 'scheduled' },
          { name: 'Shared', value: 'shared' },
          { name: 'Liked', value: 'liked' },
          { name: 'Disliked', value: 'disliked' },
        ],
      }
    },

    watch: {
      selectedYearMonth() {
        this.prep_data()
      },
    },
    methods: {
      formatDate(date) {
        return format(parseISO(date), 'MMM yyyy')
      },
      delete_shared_link(item) {
        const chat_id = item.id
        item.public = false

        // call api /api/delete_shared_link
        axios
          .post('/api/delete_shared_chat', { chat_id: chat_id })
          .then(response => {
            this.notify.success('Link deleted!')
          })
          .catch(error => {
            console.log(error)
          })
      },
      get_shared_link(chat_id) {
        // load org_id from user
        const org_id = this.user.org_id
        // build link
        const share_link = `${window.location.origin}/share/${chat_id}?org_id=${org_id}`

        // copy link to clipboard
        navigator.clipboard.writeText(share_link).then(
          () => {
            console.log('Text copied to clipboard: ' + share_link)
            // notify user that link is copied
            this.notify.success('Link copied!')
          },
          err => {
            console.error('Could not copy text: ', err)
          }
        )
      },
      prep_data() {
        // Convert and group by day

        // Parse selected year and month
        const selectedDate = parseISO(`${this.selectedYearMonth}-01`)
        const selectedYear = getYear(selectedDate)
        const selectedMonth = getMonth(selectedDate)

        // Create an array with all days of the selected month
        let daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate()
        let groupedData = Array.from({ length: daysInMonth }, (_, i) => {
          const day = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`
          return { x: day, y: 0 }
        })

        // Process data
        for (const item of this.chats) {
          const date = parseISO(item.date)
          if (isSameMonth(date, new Date(selectedYear, selectedMonth)) && getYear(date) === selectedYear) {
            const day = format(date, 'yyyy-MM-dd')
            const index = groupedData.findIndex(d => d.x === day)
            if (index !== -1) {
              groupedData[index].y += Math.round((item.messages_length + (item.edits_length || 0)) / 2)
            }
          }
        }

        // Pass the data directly to series
        this.series = [{ name: 'messages', data: groupedData }]
      },
      getTimeAgo(dateString) {
        if (!dateString) {
          return ''
        }

        const date = new Date(dateString) // Make sure the input date is treated as UTC

        // No need to convert to user's timezone if you just want to find the elapsed time
        // Because elapsed time is the same regardless of timezone

        // Calculate the time ago
        return formatDistanceToNow(date, { addSuffix: true })
      },
      load_chats() {
        axios
          .get('/api/chat_history_all')
          .then(response => {
            this.chats = response.data || []
            console.log(this.chats, 'chats')
            this.prep_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
      getMessageNumber(number) {
        return Math.floor(number / 2)
      },
      changeFilter(filter) {
        this.current_filter = filter
      },
      deleteSchedule(item) {
        try {
          const schedule = JSON.parse(item.schedules)[0]

          let chat_id = item.id
          let job_id = schedule.job_id
          axios
            .post('/api/remove_job', { job_id, chat_id }, { withCredentials: true })
            .then(() => {
              this.notify.success('Chat unscheduled successfully')
              this.load_chats()
            })
            .catch(error => {
              console.log(error)
              this.notify.error('Could not unschedule chat')
            })
        } catch (error) {
          console.log(error)
          this.notify.error('An error occurred while unscheduling chat')
        }
      },
    },
    created() {
      this.load_chats()
    },
    computed: {
      filteredItems() {
        // order by date desc (not in place)
        let sorted = this.chats.slice().sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })

        // filter by user_id or first_question
        sorted = sorted.filter(item => {
          return (
            (item.user_id && item.user_id.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.first_question && item.first_question.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.overall_feedback &&
              (item.overall_feedback > 0 ? 'success' : 'problem').includes(this.search_input.toLowerCase()))
          )
        })

        switch (this.current_filter) {
          case 'all':
            return sorted
          case 'scheduled':
            return sorted.filter(chat => {
              return JSON.parse(chat.schedules)?.length > 0
            })
          case 'shared':
            return sorted.filter(chat => chat.public)
          case 'liked':
            return sorted.filter(chat => chat.overall_feedback > 0)
          case 'disliked':
            return sorted.filter(chat => chat.overall_feedback < 0)
          default:
            return sorted
        }
      },
      availableMonths() {
        if (this.chats.length === 0 || this.chats[0].date === undefined) {
          return []
        } else {
          const months = Array.from(new Set(this.chats.map(item => format(parseISO(item.date), 'yyyy-MM'))))
          const currentMonth = format(new Date(), 'yyyy-MM')
          if (!months.includes(currentMonth)) {
            months.push(currentMonth)
          }
          return months.sort()
        }
      },
      totalMessagesLength() {
        const selectedDate = parseISO(`${this.selectedYearMonth}-01`)
        const selectedYear = getYear(selectedDate)

        return this.chats.reduce((accumulator, currentObject) => {
          const date = parseISO(currentObject.date)
          if (isSameMonth(date, selectedDate) && getYear(date) === selectedYear) {
            return accumulator + Math.round((currentObject.messages_length + (currentObject.edits_length || 0)) / 2)
          } else {
            return accumulator
          }
        }, 0)
      },
    },
  }
</script>
