<template>
  <div>
    <h1>Logout</h1>
  </div>
</template>

<script>
  export default {
    name: 'LogoutPage',
    mounted() {
      localStorage.removeItem('login')
      // Remove all cookies
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
      this.$router.push('/login')
    },
  }
</script>

<style></style>
