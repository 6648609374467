<template>
  <div class="my_container bg-white text-black dark:text-white dark:bg-gray-950">
    <ChatComponent :shared_cid="shared_cid" />
  </div>
</template>

<script>
  import ChatComponent from './components/ChatComponent.vue'
  import { onMounted } from 'vue'
  import { initModals } from 'flowbite'

  export default {
    name: 'SharePage',
    components: {
      ChatComponent,
    },
    setup() {
      onMounted(() => {
        initModals()
      })
    },
    computed: {
      shared_cid() {
        console.log(this.$route.params.cid)
        // get cid from url path /share/:cid
        console.log(this.$route.params)
        return this.$route.params.cid
      },
    },
  }
</script>

<style>
  .my_container {
    width: 100%;
    min-height: 100%;
    /*padding-top: 50px;*/
  }
</style>
