/* global posthog */
/* global $chatwoot */

import { ref } from 'vue'
import { defineStore } from 'pinia'
import router from '@/router.js'
import axios from '@/axiosInstance'
import { useGlobalStore } from '@/stores/globalStore'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('user', () => {
  const globalStore = useGlobalStore()

  // a reactive state
  const user = ref({})
  const isDarkMode = ref(document.querySelector('html').classList.contains('dark'))
  const chatMessages = ref([])
  const modelMessage = ref('')

  const currentConversation = ref(null)

  function setUser(newUser) {
    user.value = newUser
  }

  async function toggleDarkMode() {
    isDarkMode.value = !isDarkMode.value
  }

  // Check if user is logged in by calling /api/auth/me
  async function checkLogin() {
    try {
      const response = await axios.get('/api/auth/me', { withCredentials: true })

      if (!response.data.role) {
        response.data.role = 'admin'
      }

      setUser(response.data)
      if (window && window.posthog) {
        posthog.identify(response.data.id)
        posthog.people.set({
          email: response.data.email,
          name: response.data.realname,
          org_id: response.data.org_id,
        })
      }

      if (window && window.$chatwoot) {
        $chatwoot.setUser(response.data.email, {
          email: response.data.email,
          name: response.data.realname,
          identifier: response.data.id,
          custom_attributes: {
            org_id: response.data.org_id,
          },
        })
      }

      if (response.data.role === 'admin') {
        // Run getSubscriptionStatusFromServer from the globalStore
        globalStore.getSubscriptionStatusFromServer()
      }

      return true
    } catch (error) {
      if ([401, 403, 406].includes(error.response.status)) {
        console.log('not logged in')
        setUser({})
        router.push('/logout')
      } else {
        console.log(error)
      }
    }
  }

  async function getChatMessages() {
    try {
      const response = await axios.get(`/api/chat_history`)
      chatMessages.value = response.data || []
    } catch (error) {
      console.log(error)
    }
  }

  function updateChatMessages(newMessage) {
    chatMessages.value = [...chatMessages.value, newMessage]
  }

  return {
    user,
    currentConversation,
    setUser,
    checkLogin,
    toggleDarkMode,
    isDarkMode,
    chatMessages,
    getChatMessages,
    modelMessage,
    updateChatMessages,
  }
})
