import { ref } from 'vue'
import { defineStore } from 'pinia'

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const useNewChatStore = defineStore('newChat', () => {
  const currentChatId = ref('')

  // fetch ongoimg chat id from session storage
  const id = sessionStorage.getItem('ongoingChatId')
  const ongoingChatId = ref(id)

  // fetch messages from session storage
  const messages = JSON.parse(sessionStorage.getItem('localMessages')) || []
  const localMessages = ref(messages)

  function createNewChat() {
    currentChatId.value = generateUUID()
    ongoingChatId.value = null

    // save ongoing chat id to session storage
    sessionStorage.setItem('ongoingChatId', null)
  }

  function setCurrentChatId(id) {
    currentChatId.value = id
  }

  function setOngoingChatId(id) {
    ongoingChatId.value = id

    // save ongoing chat id to session storage
    sessionStorage.setItem('ongoingChatId', id)
  }

  function addMessage(message) {
    localMessages.value = [...localMessages.value, message]

    // save messages to session storage
    sessionStorage.setItem('localMessages', JSON.stringify(localMessages.value))
  }

  function setLocalMessages(messages) {
    localMessages.value = messages

    // save messages to session storage
    sessionStorage.setItem('localMessages', JSON.stringify(localMessages.value))
  }

  return {
    currentChatId,
    ongoingChatId,
    createNewChat,
    setCurrentChatId,
    setOngoingChatId,
    addMessage,
    localMessages,
    setLocalMessages,
  }
})
