<template>
  <!-- code copied from  https://flowbite.com/blocks/application/sidenav/  -->
  <aside class="relative">
    <div class="block md:hidden absolute top-4 left-2 w-max z-10">
      <button
        v-if="!showSidebar"
        class="rounded-full bg-white dark:bg-gray-950 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 w-8 h-8 flex flex-col justify-center items-center"
        @click="toggleSidebar(true)"
      >
        <MenuIcon />
      </button>
    </div>
    <div
      class="top-0 left-0 h-full transition-all duration-500 ease-in-out max-w-[260px] md:relative fixed z-40"
      :class="{
        'z-10 w-[260px]': isNavExpanded,
        'relative w-[50px]': !isNavExpanded,
        'hidden md:block': !showSidebar,
      }"
      aria-label="Sidenav"
    >
      <button
        v-if="!isMobileDevice"
        class="absolute text-gray-500 dark:text-gray-400 rounded-full top-1/2 translate-y-[-50%] -right-9 bg-white z-10 dark:bg-gray-950 hover:bg-gray-100 dark:hover:bg-gray-900 w-8 h-8 flex flex-col justify-center items-center"
        @click="toggleNav"
      >
        <svg
          :style="isNavExpanded ? '' : 'transform: scaleX(-1)'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>
      <button
        v-if="showSidebar && isMobileDevice"
        class="absolute top-1/2 translate-y-[-50%] -right-5 rounded-full bg-white dark:bg-gray-950 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 flex flex-col justify-center items-center w-8 h-8 z-10"
        @click="toggleSidebar(false)"
      >
        <CloseIcon classList="mx-auto block w-6 h-6" />
      </button>
      <div
        class="flex flex-col overflow-y-auto pt-4 h-full bg-gray-100 dark:bg-gray-950 dark:border-r dark:border-gray-900"
      >
        <ul class="space-y-1 pb-2 mb-2 border-gray-200 dark:border-gray-800" :class="isNavExpanded ? 'px-2.5' : 'px-1'">
          <li
            class="w-full rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 cursor-pointer"
            :class="{
              linkIconStyles,
            }"
            @click.stop="newChat"
            id="chat-nav"
          >
            <router-link to="/new" :class="linkIconStyles">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>
              <span :class="linkStyles">New chat</span>
            </router-link>
          </li>
          <li v-if="user.role === 'admin'" id="history-nav">
            <router-link to="/history" :class="linkIconStyles">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                />
              </svg>
              <span :class="linkStyles">History</span>
            </router-link>
          </li>
          <li v-if="user.role === 'admin'" id="model-nav">
            <router-link to="/model" :class="linkIconStyles">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span :class="linkStyles">Model</span>
            </router-link>
          </li>
          <li v-if="user.role === 'admin'" id="settings-nav">
            <router-link to="/settings" :class="linkIconStyles">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span :class="linkStyles">Settings</span>
            </router-link>
          </li>
        </ul>
        <!--              sidebar banner-->
        <div
          v-if="showBanner && isNavExpanded"
          id="banner"
          tabindex="-1"
          class="mt-2 flex gap-8 m-2 mx-auto max-w-[180px] justify-between rounded-xl items-start py-3 px-4 w-full bg-orange bg-opacity-10 sm:items-center lg:py-4 relative"
        >
          <p class="text-xs text-orange mr-3">
            Try Dot on ecommerce demo data.
            <a class="text-xs underline" href="https://docs.getdot.ai/whats-dot/getting-started" target="_blank">
              Then, Connect your data.
            </a>
          </p>
          <button
            @click.prevent="showBanner = false"
            data-collapse-toggle="banner"
            type="button"
            class="flex items-center text-orange hover:bg-orange hover:bg-opacity-50 hover:text-gray-900 rounded-xl text-sm p-1.5 dark:hover:bg-orange dark:hover:bg-opacity-50 dark:hover:text-white absolute top-1 right-1"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="isNavExpanded" class="overflow-y-scroll" :class="isNavExpanded ? 'px-3' : 'px-1'">
          <div v-if="pinnedChatMessages.length" class="mt-2 mb-2">
            <div class="p-1 px-2 font-normal text-gray-400 dark:text-gray-500 text-xs">Favorites</div>
            <ul class="">
              <li
                v-for="chatMessage in pinnedChatMessages"
                :key="chatMessage.id"
                v-tooltip.right="chatMessage.title ? chatMessage.title : chatMessage.first_question"
                class="sidebarHistoryElement flex items-center justify-between"
              >
                <router-link
                  :to="{
                    path: '/',
                    query: {
                      c: chatMessage.id,
                    },
                  }"
                  :class="linkIconStyles"
                  class="fade_out text-sm"
                >
                  {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                </router-link>
                <button
                  v-tooltip="'Unpin Chat'"
                  style="display: none"
                  class="pinButton text-black dark:text-white active:scale-95 p-1 rounded-full mr-0.5 hover:bg-gray-200 dark:hover:bg-gray-900"
                  @click.stop="pinChat(chatMessage)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                    <path stroke-linecap="round" d="M3 3l18 18" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
          <div v-for="(chatMessages, date) in chatMessagesByDate" :key="date">
            <div
              v-tooltip="{
                content: `<small>${new Date(chatMessages[0]?.date).toLocaleString()}</small>`,
                html: true,
              }"
              class="font-normal text-gray-400 dark:text-gray-500 text-xs p-1 px-2 mt-4"
            >
              {{ date }}
            </div>
            <ul class="">
              <li
                v-for="chatMessage in chatMessages"
                :key="chatMessage.id"
                v-tooltip.right="chatMessage.title ? chatMessage.title : chatMessage.first_question"
                class="sidebarHistoryElement flex items-center justify-between"
              >
                <router-link
                  :to="{
                    path: '/',
                    query: {
                      c: chatMessage.id,
                    },
                  }"
                  :class="linkIconStyles"
                  class="fade_out text-sm"
                >
                  {{ chatMessage.title ? chatMessage.title : chatMessage.first_question }}
                </router-link>
                <button
                  v-tooltip="'Pin Chat'"
                  style="display: none"
                  class="pinButton text-black dark:text-white active:scale-95 p-1 rounded-full mr-0.5 hover:bg-gray-200 dark:hover:bg-gray-900"
                  @click.stop="pinChat(chatMessage)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="justify-center p-2.5 pt-6 mt-auto w-full bg-gray-100 dark:bg-gray-950 text-gray-400 dark:text-gray-500 text-center"
          :class="isNavExpanded ? '' : 'px-1'"
        >
          <div class="flex items-center justify-center" :class="isNavExpanded ? 'flex-row' : 'flex-col'">
            <img
              alt="logo"
              class="rounded-full m-2"
              :class="isNavExpanded ? 'w-[60px]' : 'w-10'"
              src="../assets/fox_avatar_7.jpg"
              v-tooltip="randomJoke()"
            />

            <!-- user info with dropdown -->
            <button
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              :data-dropdown-placement="isNavExpanded || isMobileDevice ? 'top' : 'right'"
              class="w-full text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm px-2 py-2.5 text-center inline-flex items-center justify-center dark:text-white dark:bg-gray-950 dark:hover:bg-gray-900 dark:focus:ring-gray-950"
              type="button"
            >
              <span class="inline" v-if="isNavExpanded">{{ user.realname ? user.realname : '···' }}</span>
              <svg
                v-if="!isNavExpanded"
                class="w-4 h-4 md:ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                ></path>
              </svg>
            </button>
          </div>

          <!-- Dropdown menu -->
          <div
            id="dropdown"
            class="z-50 hidden bg-white divide-y divide-gray-100 rounded-xl shadow dark:bg-gray-900 dark:divide-gray-900"
          >
            <div class="px-4 py-3 text-sm text-gray-900 dark:text-white w-64">
              <div class="font-sm truncate">{{ user.id }}</div>
              <div class="font-xs truncate font-mono text-gray-400 dark:text-gray-600">
                {{ user.org_id }}
              </div>
            </div>
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
              <li>
                <router-link
                  to="/logout"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Sign out
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
  import { onMounted, ref, watch, computed } from 'vue'
  import { initDropdowns } from 'flowbite'
  import { useUserStore } from '@/stores/userStore'
  import { useOrgStore } from '@/stores/orgStore'
  import { useNewChatStore } from '@/stores/chatStore'
  import { storeToRefs } from 'pinia'
  import LongArrowLeftIcon from './icons/LongArrowLeftIcon.vue'
  import LongArrowRightIcon from './icons/LongArrowRightIcon.vue'
  import MenuIcon from './icons/MenuIcon.vue'
  import CloseIcon from './icons/CloseIcon.vue'
  import axios from '@/axiosInstance'
  import { useRoute, useRouter } from 'vue-router'
  import { formatDistanceToNow, parseISO } from 'date-fns'

  export default {
    name: 'SideNavigation',
    setup() {
      const route = useRoute()
      const router = useRouter()

      const userStore = useUserStore()
      const orgStore = useOrgStore()
      const { user, chatMessages } = storeToRefs(userStore)
      const { org, connections } = storeToRefs(orgStore)
      const newChatStore = useNewChatStore()

      // expand or collapse sidenav
      const isNavExpanded = ref(!window.matchMedia('(max-width: 820px)').matches)

      // state to handle the visibility of the banner on mobile
      const showSidebar = ref(false)

      const toggleSidebar = value => {
        showSidebar.value = value

        if (value) {
          isNavExpanded.value = true
        }
      }

      const showBanner = ref(false)

      // central styling for navlinks to enable mass editing
      const linkIconStyles = ref(
        `cursor-pointer flex items-center p-2 text-sm font-normal text-gray-900 rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 group ${
          isNavExpanded.value ? '' : 'justify-center'
        }`
      )
      const linkStyles = ref(isNavExpanded.value ? 'ml-3 visible' : 'ml-3 collapse hidden')

      // isMobileDevice
      const isMobileDevice = ref(window.matchMedia('(max-width: 520px)').matches)

      // Watch for changes in the window width
      const mediaQuery = window.matchMedia('(max-width: 820px)')
      const handleMediaQueryChange = mediaQuery => {
        isNavExpanded.value = !mediaQuery.matches
        isMobileDevice.value = window.matchMedia('(max-width: 520px)').matches
      }

      // Set the initial state based on the window width
      handleMediaQueryChange(mediaQuery)

      // Watch for changes in the window width
      mediaQuery.addEventListener('change', handleMediaQueryChange)

      // Watch for changes in isNavExpanded
      watch(isNavExpanded, newValue => {
        if (newValue) {
          linkStyles.value = 'ml-3 visible'
          linkIconStyles.value =
            'cursor-pointer flex items-center p-2 text-base font-normal text-gray-900 rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 group'
        } else {
          linkStyles.value = 'ml-3 collapse hidden'
          linkIconStyles.value =
            'cursor-pointer flex items-center p-2 text-base font-normal text-gray-900 rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-900 group justify-center w-full'
        }
      })

      // watch for changes in the connection number
      watch(
        connections,
        val => {
          if (val.length === 0) {
            showBanner.value = true
          } else if (val.length > 1) {
            showBanner.value = false
          } else {
            showBanner.value = val[0].type === 'plugin'
          }
        },
        { immediate: true, deep: true }
      )

      // hide the sidebar when the route changes
      router.afterEach(() => {
        showSidebar.value = false
      })

      function toggleNav() {
        isNavExpanded.value = !isNavExpanded.value
      }

      // Compute if the current route is the root route ("/") to conditionally render the new chat button
      const isRootRoute = computed(() => route.path === '/' || route.path === '/new')

      function newChat() {
        // remove questionRunning, ongoingChatId, and localMessages from sessionStorage
        sessionStorage.removeItem('questionRunning')
        sessionStorage.removeItem('ongoingChatId')
        sessionStorage.removeItem('localMessages')
        newChatStore.createNewChat()

        router.push('/new')
      }

      // creates a calculated property that clusters chatMessages by date
      const chatMessagesByDate = computed(() => {
        const chatMessagesByDate = {}

        // Filter out chatMessages that have been pinned
        const filteredChatMessages = chatMessages.value.filter(chatMessage => {
          return !chatMessage.pinned
        })

        // First order the chatMessages by date so that the newest messages are first
        filteredChatMessages.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })

        for (const chatMessage of filteredChatMessages) {
          const utcDate = parseISO(chatMessage.date)
          const date = formatDistanceToNow(utcDate, { addSuffix: true })

          if (!chatMessagesByDate[date]) {
            chatMessagesByDate[date] = []
          }
          chatMessagesByDate[date].push(chatMessage)
        }

        return chatMessagesByDate
      })

      const pinnedChatMessages = computed(() => {
        return chatMessages.value.filter(chatMessage => {
          return chatMessage.pinned
        })
      })

      onMounted(() => {
        initDropdowns()
        orgStore.getOrg()

        if (!chatMessages.value.length) {
          userStore.getChatMessages()
        }

        orgStore.getConnections(true).then(() => {
          if (orgStore.connections.length === 0) {
            showBanner.value = true
          } else if (orgStore.connections.length > 1) {
            showBanner.value = false
          } else {
            if (orgStore.connections[0].type === 'plugin') {
              showBanner.value = true
            }
          }
        })
      })

      const pinChat = async chatMessage => {
        chatMessage.pinned = !chatMessage.pinned

        // update the chatMessage in the database
        await axios.post('/api/pinChat', {
          chat_id: chatMessage.id,
          pin: chatMessage.pinned,
        })
      }

      return {
        user,
        org,
        linkIconStyles,
        linkStyles,
        isNavExpanded,
        toggleNav,
        window,
        newChat,
        isRootRoute,
        router,
        chatMessagesByDate,
        formatDistanceToNow,
        pinChat,
        pinnedChatMessages,
        showSidebar,
        toggleSidebar,
        isMobileDevice,
        showBanner,
      }
    },
    methods: {
      randomJoke() {
        const jokes = [
          'Why did I break up with my ex? I had too many relationship tables. But I will never let YOU down.',
          'Why did the data analyst get thrown out of the bar? They kept trying to normalize the drinks.',
          'What’s a data analyst’s favorite part of a joke? The punchline is statistically significant.',
          "Why don't data analysts tell time jokes? They don't want to deal with interval errors.",
          "How does a data analyst propose marriage? 'Will you be the y to my x?'",
          "You know what's my favorite drink? Bubble tea – because of all the floating points.",
          'Why did the data analyst stay calm during the zombie apocalypse? They knew it was just a cluster analysis gone wrong.',
          "How does a data analyst say goodbye? 'I'll catch you on the flip side of this bell curve.'",
          'Why do data analysts love the school playground? They get a kick out of the swings and roundabouts in the data.',
          "What did the data analyst say to the skeptical manager? 'I think you'll find these numbers quite significant.'",
          'Why do data analysts make terrible comedians? They always spoil the punchline by explaining the variance.',
          'How do data analysts fix a broken database? With table joins.',
          'There are two kinds of data scientists. 1.) Those who can extrapolate from incomplete data.',
          'Analysis is 80% preparing data, 19% complaining about preparing data and 1% about asking Dot.',
          'There are 10 kinds of people in this world. Those who understand binary and those who don’t.',
          'The data science motto: If at first, you don’t succeed; call it version 0.1',
          'A SQL query walks into a bar, walks up to two tables, and asks, “Can I join you?”',
          'An analyst walks into a bar, orders a beer, and orders 0 beers, 999999999 beers, -1 beers, a lizard, and a sfdeljknesv.',
          'Why should you take a data scientist with you into the jungle? Answer: They can take care of Python problems.',
          'Data is like people – interrogate it hard enough and it will tell you whatever you want to hear.',
          'You are so mean that your standard deviation is zero.',
          'Two random variables were talking in a bar. They thought they were being discreet, but I heard their chatter continuously.',
          'A statistician’s wife had twins. He was delighted. He rang the minister who was also delighted. “Bring them to church on Sunday and we’ll baptize them,” said the minister. “No,” replied the statistician. “Baptize one. We’ll keep the other as a control.”',
          'If all your friends jumped off a bridge, would you follow them? Machine Learning Algorithm: yes.',
          'I have a joke about deep learning, but I can’t explain it.',
          'I have a machine learning joke, but it is not performing as well on a new audience.',
        ]
        const index = Math.floor(Math.random() * jokes.length)
        return jokes[index]
      },
    },
    components: { LongArrowLeftIcon, LongArrowRightIcon, MenuIcon, CloseIcon },
  }
</script>

<style scoped>
  .fade_out {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
  }

  .sidebarHistoryElement {
    transition: all 0.2s ease-in-out;
  }

  .sidebarHistoryElement:hover .pinButton {
    display: block !important;
  }
</style>
