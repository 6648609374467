<!-- Settings Drawer -->
<template>
  <Popup ref="saveChangesPopup" @cancel="discardItem()" @confirm="saveItem()">
    <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
      <h2 class="text-xl font-bold mb-2">Save Changes</h2>
      <p class="text-sm">You have unsaved changes. Do you want to save them?</p>
    </div>
  </Popup>
  <div v-if="isMobileDevice" class="px-[24px] pb-12 bg-white text-black dark:text-white dark:bg-gray-950 w-full">
    Please switch to a bigger device to view the model page.
  </div>
  <div
    v-else
    class="px-0 md:px-0 pb-12 py-4 overflow-y-scroll bg-white text-black dark:text-white dark:bg-gray-950 w-full"
  >
    <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Dashboards & More</h3>
    <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
      Select the external assets Dot should know about.
      <br />
      Click on a external asset to configure its description.
    </p>
    <div class="h-5"></div>

    <!-- Table for data start -->
    <div class="relative overflow-x-auto border dark:border-gray-900 sm:rounded-xl">
      <div class="bg-white dark:bg-gray-950">
        <label for="table-search" class="sr-only">Search</label>
        <div class="flex flex-col sm:flex-row sm:justify-between">
          <div class="relative pl-3">
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400 absolute pointer-events-none top-1/2 left-3 -translate-y-1/2"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <input
              type="text"
              id="table-search"
              v-model="search"
              @input="this.page = 1"
              class="block p-2 pl-6 text-sm text-gray-900 border-0 rounded-xl w-80 focus:ring-0 focus:outline-none focus:dark:text-white dark:bg-gray-950 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search.."
            />
          </div>
        </div>
      </div>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase border-t border-b border-gray-100 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-400">
          <tr>
            <th scope="col" class="p-2 pl-4">Active</th>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center">
                External Asset
              </div>
            </th>
            <th scope="col" class="px-6 py-3">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredTableItems"
            :key="item.id"
            @click="selectTable(item)"
            class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900"
            :class="{
              'cursor-pointer': table_loading !== item.id,
            }"
            :disabled="table_loading === item.id"
          >
            <td class="w-4 p-2">
              <div v-if="table_loading !== item.id" class="flex items-center">
                <input
                  id="checkbox-table-search-1"
                  type="checkbox"
                  v-tooltip="item.active ? 'Deactivate' : 'Activate'"
                  :checked="item.active"
                  @click.stop="toggleTableStatus(item)"
                  class="w-5 h-5 mx-auto text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
              </div>
              <div v-else class="flex items-center">
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-5 h-5 mx-auto text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </td>
            <th
              scope="row"
              alt="item.name"
              class="pl-6 pr-2 py-4 text-xs max-w-md truncate font-medium whitespace-nowrap flex items-center"
              :class="item.archived ? 'line-through text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-white'"
              v-tooltip="item.name ? `Edit ${item.name}` : `Edit data source`"
            >
              <svg
                v-if="item.dot_ml"
                v-tooltip="'Dimensions & Metrics are defined'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2.0"
                stroke="currentColor"
                class="w-5 h-5 ml-1 stroke-green-500 dark:stroke-green-300 shrink-0"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
              {{ item.name ? item.name.split('.').slice(-1)[0] : '' }}
              <!-- show first part of the name without last slice -->
              <span class="text-xs ml-1 text-gray-400 dark:text-gray-500">
                {{ item.folder ? item.folder : '' }}
              </span>
            </th>
            <td
              class="pl-6 pr-2 py-4 text-xs max-w-2xl truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
            >
              {{ item.dot_description }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex flex-col sm:flex-row items-center gap-2 sm:gap-0 p-2 dark:bg-gray-950">
        <!-- Help text -->
        <span class="text-sm text-gray-500 dark:text-gray-400 sm:ml-2">
          Showing
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ this.filteredTableItems.length > 0 ? (this.page - 1) * this.perPage + 1 : 0 }}
          </span>
          to
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ tablesShown }}
          </span>
          of
          <span class="font-semibold text-gray-600 dark:text-white">{{ allTables }}</span>
        </span>
        <div class="inline-flex sm:ml-auto xs:mt-0">
          <!-- Buttons -->
          <button
            @click="this.page = Math.max(this.page - 1, 1)"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-l-2xl hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Prev
          </button>
          <button
            @click="this.page = Math.min(this.page + 1, Math.max(Math.ceil(this.items.length / this.perPage), 1))"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 rounded-r-2xl hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
            <svg
              aria-hidden="true"
              class="w-5 h-5 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Table for data end -->

    <!-- drawer init and toggle -->
    <div class="text-center">
      <button
        ref="sideDrawerButton"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        type="button"
        style="visibility: hidden"
        data-drawer-backdrop="false"
        data-drawer-target="drawer-right-example"
        data-drawer-show="drawer-right-example"
        data-drawer-placement="right"
        aria-controls="drawer-right-example"
      >
        Show right drawer
      </button>
    </div>

    <!-- drawer component for tables-->
    <div
      id="drawer-right-example"
      class="fixed top-0 right-0 z-[80] h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-[80%] min-w-[800px] dark:bg-gray-950 shadow"
      tabindex="-1"
      aria-labelledby="drawer-right-label"
    >
      <div class="flex flex-row items-center">
        <h3 id="drawer-right-label" class="text-lg font-bold text-left px-1">Configure External Asset</h3>
        <span class="flex-1"></span>
        <button
          v-if="drawerItem.org_id == org.id"
          @click="saveItem()"
          :class="save_enabled ? 'bg-blue-700 dark:bg-blue-600' : 'bg-gray-500 dark:bg-gray-600'"
          :disabled="!save_enabled"
          v-tooltip="save_enabled ? 'Save changes' : 'No changes to save'"
          class="ml-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white rounded-xl hover:bg-blue-800 disabled:hover:bg-gray-500 focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 dark:disabled:hover:bg-gray-600 focus:outline-none dark:focus:ring-blue-800"
        >
          <span v-if="!loading_save">Save</span>
          <span v-else>Loading...</span>

          <svg
            v-if="!loading_save"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            :class="save_enabled ? 'animate-pulse' : ''"
            class="ml-2 w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-width="2"
              stroke-linejoin="round"
              d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <svg
            v-else
            aria-hidden="true"
            role="status"
            class="inline w-4 h-4 ml-3 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button
          v-show="!save_enabled && !changes_made"
          type="button"
          data-drawer-hide="drawer-right-example"
          aria-controls="drawer-right-example"
          ref="closeButtonTable"
          @click="handleCloseTable()"
          class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
        <button
          v-show="save_enabled || changes_made"
          type="button"
          @click="handleCloseTable()"
          class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
      </div>

      <p class="text-xs mb-6 text-gray-500 dark:text-gray-400 text-left px-1">
        Describe this external asset.
      </p>

      <!-- show warning in red if it is set -->
      <p v-if="this.drawerItem.warning" class="text-sm mb-2 text-red-500 dark:text-red-400 text-left px-2">
        {{ this.drawerItem.warning }}
      </p>

      <p class="mb-2 text-sm medium text-gray-900 dark:text-white ml-2">
        {{ this.drawerItem.id }} <span v-if="this.drawerItem.num_rows" class="text-xs text-gray-500 dark:text-gray-400" v-tooltip="this.drawerItem.num_rows">  ·  {{ millify(this.drawerItem.num_rows) }} rows</span>
        <!-- Folder -->
        <span v-if="this.drawerItem.folder" class="text-xs text-gray-500 dark:text-gray-400" v-tooltip="this.drawerItem.folder">  ·  {{ this.drawerItem.folder }}</span>
      </p>
      <textarea
        id="message"
        rows="3"
        v-model="this.drawerItem.dot_description"
        class="block dot-inline-shadow p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-0 rounded-xl focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Each row represents an order ..."
        :disabled="true"
      ></textarea>

      <div class="h-20"></div>
    </div>

    <!-- end side drawer for tables -->
  </div>

  <div
    id="my_backdrop"
    v-if="backdropIsVisible"
    @click="handleCloseTable()"
    class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[70]"
  ></div>
</template>

<style>
  .dot-inline-shadow {
    -webkit-box-shadow: inset 0px -5px 8px -2px rgba(129, 129, 129, 0.22);
    box-shadow: inset 0px -5px 8px -4px rgba(129, 129, 129, 0.22);
  }
</style>

<script>
  import { onMounted, ref } from 'vue'
  import { initDrawers, initTabs } from 'flowbite'
  import Popup from './Popup.vue'
  import MonacoEditor from 'monaco-editor-vue3'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import CloseIcon from './icons/CloseIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'

  export default {
    name: 'ExternalAssetPage',
    components: {
      MonacoEditor,
      CloseIcon,
      Popup,
    },
    setup() {
      const orgStore = useOrgStore()
      const { org } = storeToRefs(orgStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      onMounted(() => {

        document.title = 'External Assets · Dot'
        initDrawers()
        initTabs()
      })

      // expand or collapse sidenav
      const isMobileDevice = ref(window.matchMedia('(max-width: 520px)').matches)

      // Watch for changes in the window width
      const mediaQuery = window.matchMedia('(max-width: 520px)')
      const handleMediaQueryChange = mediaQuery => {
        isMobileDevice.value = mediaQuery.matches
      }

      // Set the initial state based on the window width
      handleMediaQueryChange(mediaQuery)

      // Watch for changes in the window width
      mediaQuery.addEventListener('change', handleMediaQueryChange)

      return {
        org,
        isMobileDevice,
        notify,
      }
    },
    created() {
      this.getExternalAssets()
    },
    watch: {
      drawerItem: {
        deep: true,
        handler(newVal, oldVal) {
          // Check if the ID is new
          if (newVal.id !== oldVal.id) {
            console.log(`New ID: ${newVal.id}`)
          } else {
            this.save_enabled = true
            this.changes_made = true
          }
        },
      },
    },
    computed: {
      filteredTableItems() {
        return this.items
          .filter(item => {
            return (
              ((item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.folder && item.folder.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.dot_description && item.dot_description.toLowerCase().includes(this.search.toLowerCase())))
            )
          })
          .slice((this.page - 1) * this.perPage, (this.page - 1) * this.perPage + this.perPage)
      },
      tablesShown() {
        if (this.search) {
          return this.filteredTableItems.length
        } else {
          if (!this.items) {
            return 0
          }
          return this.page * this.perPage > this.items.length
            ? this.items.filter(item => {
                return (
                  ((item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
                    (item.folder && item.folder.toLowerCase().includes(this.search.toLowerCase())) ||
                    (item.dot_description && item.dot_description.toLowerCase().includes(this.search.toLowerCase()))) &&
                  item.name !== 'dot.meta.summary'
                )
              }).length
            : this.page * this.perPage
        }
      },
      allTables() {
        return this.items.length
      },
    },
    data() {
      return {
        backdropIsVisible: false,
        save_enabled: false,
        loading_save: false,
        search: '',
        page: 1,
        perPage: 10,
        forceRerender: 0,
        drawerItem: {},
        items: [{ name: 'Loading ..', dot_description: '', active: false }],
        changes_made: false,
        table_loading: false,
      }
    },
    methods: {
      getExternalAssets() {
        axios
          .get('/api/external_assets', { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.items = response.data
              // if item has attribute 'archive' set, set 'active' to true
              this.items.forEach(item => {
                if (item.archived) {
                  item.active = true
                }
              })

              // order items by active first
              this.items.sort((a, b) => {
                return a.active === b.active ? 0 : a.active ? -1 : 1
              })

              // order all columns (if exists) in each table by active first
              this.items.forEach(item => {
                if (item.columns) {
                  item.columns.sort((a, b) => {
                    return a.active === b.active ? 0 : a.active ? -1 : 1
                  })
                }
              })

              const potentialUpdatedDrawerItem = this.items.filter(item => item.id === this.drawerItem.id)

              if (potentialUpdatedDrawerItem.length > 0) {
                this.drawerItem = potentialUpdatedDrawerItem[0]
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      millify(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
},
      async toggleTableStatus(external_asset) {
        if (external_asset.org_id !== this.org.id) {
          this.notify.error('Demo data items are not editable')
          this.items = this.items.map(item => {
            if (item.id === external_asset.id) {
              item.active = true
            }
            return item
          })
        } else {
          this.table_loading = external_asset.id

          external_asset = { ...external_asset, active: !external_asset.active }
          this.items = this.items.map(item => {
            if (item.id === external_asset.id) {
              item.active = external_asset.active
            }
            return item
          })

          this.loading_save = true

          // save item status (active or not)
          axios
            .post('/api/save_external_asset', { external_asset }, { withCredentials: true })
            .then(async response => {
              console.log(response.data)
              if (external_asset.active) {
                this.notify.success('External asset enabled')
              } else {
                this.notify.success('External asset disabled')
              }

              console.log('Saved Table')

              this.loading_save = false

              this.table_loading = null

              console.log('Table loading', this.table_loading)
            })
            .catch(error => {
              console.log(error)
              this.loading_save = false
            })
        }
      },
      selectTable(item) {
        if (item.id === this.table_loading) {
          return
        }

        this.$refs.sideDrawerButton.click()

        this.drawerItem = item // set drawer item

        this.forceRerender += 1
        this.save_enabled = false
        this.backdropIsVisible = true
        this.page_drawer = 1
        initTabs()
      },
      async saveItem(item) {
        // call api to save item
        const external_asset = item || this.drawerItem

        if (external_asset.org_id !== this.org.id) {
          this.notify.error('Demo data items are not editable')
          return
        }

        this.loading_save = true

        axios
          .post('/api/save_external_asset', { external_asset }, { withCredentials: true })
          .then(response => {
            console.log(response.data)
            this.notify.success('Saved successfully')
            this.loading_save = false
            this.save_enabled = false
            this.changes_made = false
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })
      },

      discardItem() {
        this.$refs.saveChangesPopup.hidePopup()
        this.backdropIsVisible = false
        this.$refs.closeButtonTable?.click()
        this.drawerItem = { }
        this.save_enabled = false
        this.changes_made = false
      },
      handleCloseTable() {
        if (this.changes_made && this.save_enabled) {
          this.$refs.saveChangesPopup.showPopup()
        } else {
          this.backdropIsVisible = false
          this.$refs.closeButtonTable.click()
        }
      }
    }
  }
</script>

<style>
  .monaco-editor-vue3 {
    height: 140px;
    background: #1e1e1e;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dark .monaco-editor-vue3 {
    background: #1e1e1e;
  }

  .virtual-text {
    width: auto;
    height: 100%;
    position: absolute;
    top: 7px;
    left: 6px;
    overflow: hidden;
  }

  .hidden-text {
    width: auto;
    visibility: hidden;
    /* border: 1px solid green; */
    float: left;
    font-size: 15px;
  }

  .autocomplete {
    color: silver;
    z-index: 5;
    background-color: transparent !important;
    width: auto;
    border-bottom: none;
    float: left;
    outline: none;
    border: none;
    /* border: 1px solid red; */
    line-height: 1.5 !important;
    padding: 0px !important;
    margin: 0px !important;
    pointer-events: none;
  }

  .grow {
    position: relative;
  }

  .stroke-green-500 {
    stroke: #22c55e;
  }

  .dark .stroke-green-300 {
    stroke: #86efac;
  }
</style>
