<template>
  <ConnectionWrapper connection-name="Dot Usage" :connection-is-active="!!mutableConnection.last_sync">
    <template v-slot:icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
        />
      </svg>
    </template>
    <div
      v-if="hasLastSync"
      v-tooltip="mutableConnection.last_sync"
      @click="emit('showLastLogs', 'usagedb')"
      class="text-xs mb-2 text-gray-500 dark:text-gray-400 hover:underline cursor-pointer"
    >
      last synced {{ lastSyncDistanceString }}
    </div>
    <div>
      <div class="mb-6"></div>
      <button
        v-if="editMode"
        @click="editMode = false"
        class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2"
      >
        Cancel
      </button>
      <button
        v-if="hasLastSync && editMode"
        @click.prevent="
          emit('showRemoveModal', {
            ...mutableConnection,
            ...{
              title: mutableConnection.host,
            },
          })
        "
        class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800 mr-2"
      >
        <span>Remove</span>
      </button>
      <button
        @click.prevent="connect"
        v-if="computedEditMode"
        :disabled="isLoading || !requiredFieldsFilled"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:bg-gray-500 disabled:dark:bg-gray-600"
      >
        <LoadingIcon v-if="isLoading" class="inline w-4 h-4 mr-3 text-white animate-spin" />
        <span v-if="isLoading">Loading...</span>
        <span v-else>Connect</span>
      </button>
      <button
        v-if="!computedEditMode"
        @click.stop.prevent="editMode = true"
        class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2"
      >
        Edit
      </button>
      <button
        @click.prevent="sync"
        v-if="hasLastSync && !computedEditMode"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <LoadingIcon class="inline w-4 h-4 mr-3 text-white animate-spin" v-if="isLoading" />
        <span v-if="isLoading">Loading...</span>
        <span v-else>Sync</span>
      </button>
    </div>
  </ConnectionWrapper>
</template>

<script setup>
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import { defineProps, defineEmits, ref, computed, watch } from 'vue'
  import { formatDistanceToNow } from 'date-fns'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const orgStore = useOrgStore()

  const editMode = ref(false)
  const isLoading = ref(false)

  const emit = defineEmits(['update:modelValue', 'showLastLogs', 'showRemoveModal', 'updateConnections'])

  const props = defineProps({
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    sync_connection: {
      type: Function,
      required: true,
    },
  })

  const mutableConnection = computed({
    get: () => props.modelValue,
    set: value => {
      emit('update:modelValue', value)
    },
  })

  const computedEditMode = computed(() => !mutableConnection.value.last_sync || editMode.value)
  const hasLastSync = computed(() => mutableConnection.value.last_sync)
  const lastSyncDistanceString = computed(() => {
    if (mutableConnection.value.last_sync && mutableConnection.value.last_sync.includes('+')) {
      return formatDistanceToNow(new Date(mutableConnection.value.last_sync), { addSuffix: true })
    } else {
      return ''
    }
  })

  const requiredFieldsFilled = computed(() => {
    return true
  })

  const connect = async () => {
    if (!requiredFieldsFilled.value) {
      notify.error('Please fill in all fields')
      return
    }

    isLoading.value = true

    try {
      let response = await axios.post(`/api/connect_usagedb`, mutableConnection.value)
      emit('updateConnections')

      notify.success(response.data)
      orgStore.getConnections(true)

      await props.sync_connection('usagedb')

      editMode.value = false
    } catch (error) {
      console.error(error)
      notify.error(error?.response?.data?.detail || 'An error occurred')
    } finally {
      isLoading.value = false
    }
  }

  const sync = async () => {
    isLoading.value = true

    await props.sync_connection('usagedb')

    isLoading.value = false
  }
</script>
