<template>
  <svg
    :class="classList ? classList : 'w-6 h-6'"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g data-name="Product Icons">
      <g>
        <path style="fill: #aecbfa" d="M6.73,10.83v2.63A4.91,4.91,0,0,0,8.44,15.2V10.83Z" />
        <path style="fill: #669df6" d="M9.89,8.41v7.53A7.62,7.62,0,0,0,11,16,8,8,0,0,0,12,16V8.41Z" />
        <path style="fill: #aecbfa" d="M13.64,11.86v3.29a5,5,0,0,0,1.7-1.82V11.86Z" />
        <path
          style="fill: #4285f4"
          d="M17.74,16.32l-1.42,1.42a.42.42,0,0,0,0,.6l3.54,3.54a.42.42,0,0,0,.59,0l1.43-1.43a.42.42,0,0,0,0-.59l-3.54-3.54a.42.42,0,0,0-.6,0"
        />
        <path
          style="fill: #669df6"
          d="M11,2a9,9,0,1,0,9,9,9,9,0,0,0-9-9m0,15.69A6.68,6.68,0,1,1,17.69,11,6.68,6.68,0,0,1,11,17.69"
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'BigQueryIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
