<template>
  <section class="bg-white dark:bg-gray-900 w-full min-h-full h-screen">
    <div class="px-6 pb-10 sm:py-8 w-full gap-10 min-h-full h-screen lg:py-0 flex flex-col justify-center">
      <div class="w-full flex justify-center flex-wrap">
        <div class="w-[448px] p-10">
          <div class="flex flex-col items-center gap-3 mb-10">
            <router-link to="/" class="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
              <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
            </router-link>
            <h1 class="text-xl font-bold text-gray-900 md:text-2xl dark:text-white hidden sm:block">Chat with Dot</h1>
          </div>
          <div class="sm:flex gap-2 mb-3 hidden">
            <CheckMarkIcon class="text-blue-600 dark:text-blue-300 flex-none" />
            <div class="flex-initial">
              <h2 class="text-base font-semibold text-gray-900 dark:text-white">Free for 100 messages per month</h2>
              <p class="text-gray-500 flex gap-2 font-normal text-sm">
                Answers data questions accurately. Track your most important metrics. Carry deep dive analysis.
              </p>
            </div>
          </div>
          <div class="sm:flex hidden gap-2 mb-3">
            <CheckMarkIcon class="text-blue-600 dark:text-blue-300 flex-none" />
            <div class="flex-initial">
              <h2 class="text-base font-semibold text-gray-900 dark:text-white">
                Start uncovering insights immediately
              </h2>
              <p class="text-gray-500 flex gap-2 font-normal text-sm">
                Leverage your cloud data warehouse, BI tool, or semantic layer easily with no-code integrations.
              </p>
            </div>
          </div>
          <div class="sm:flex hidden gap-2 mb-3">
            <CheckMarkIcon class="text-blue-600 dark:text-blue-300 flex-none" />
            <div class="flex-initial">
              <h2 class="text-base font-semibold text-gray-900 dark:text-white">Join industry leaders that ask Dot</h2>
              <p class="text-gray-500 flex gap-2 font-normal text-sm">
                Airbyte, Babbel, Flix, GLS, Virgin, and many more.
              </p>
            </div>
          </div>
        </div>
        <div class="w-[448px]">
          <div
            class="w-full bg-white rounded-2xl border dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-900 dark:border-gray-700"
          >
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create an account
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label for="realname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="realname"
                    id="realname"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Claude Shannon"
                    required=""
                    v-model="realname"
                  />
                </div>
                <div>
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Company email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="claude.shannon@bell-labs.com"
                    required=""
                    v-model="username"
                  />
                </div>
                <div>
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    v-model="password"
                  />
                </div>
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                      required=""
                      data-test="9cb2c077-deec-4493-a34e-56802f6c499a"
                      v-model="terms"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="terms" class="font-light text-gray-500 dark:text-gray-300">
                      I accept the
                      <a
                        class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                        href="https://uploads-ssl.webflow.com/644a347ea803e322d9c0feb8/647748918f17ddf6429d9045_Dot_FreeTrial_MSA_May23.doc.pdf"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                </div>
                <button
                  @click.prevent="register"
                  class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center gap-2 justify-center disabled:cursor-not-allowed"
                  :disabled="creating_account"
                  id="create_account"
                >
                  <LoadingIcon v-if="creating_account" classList="w-6 h-6 text-white dark:text-gray-400 animate-spin" />

                  Create an account
                </button>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account?
                  <router-link to="/login" class="font-medium text-blue-600 hover:underline dark:text-blue-500">
                    Login here
                  </router-link>
                  <br />
                  <!-- show the following if the url does not start with eu.getdot.ai -->
                  <span
                    v-if="hostname !== 'eu.getdot.ai'"
                    class="text-sm mt-0 font-light text-gray-500 dark:text-gray-400"
                  >
                    Region: US
                    <a
                      :href="`https://eu.getdot.ai/register?${gclid ? `gclid=${gclid}` : ''}`"
                      class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                      Switch to EU
                    </a>
                  </span>
                  <span v-else class="text-sm mt-0 font-light text-gray-500 dark:text-gray-400">
                    Region: EU
                    <a
                      :href="`https://app.getdot.ai/register?${gclid ? `gclid=${gclid}` : ''}`"
                      class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                      Switch to US
                    </a>
                  </span>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { onMounted, ref } from 'vue'

  import { useRouter } from 'vue-router'

  import axios from 'axios'
  import CheckMarkIcon from './components/icons/CheckMarkIcon.vue'
  import LoadingIcon from './components/icons/LoadingIcon.vue'
  import { useGlobalStore } from './stores/globalStore'

  const router = useRouter()

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const gclid = new URLSearchParams(window.location.search).get('gclid')

  const hostname = window.location.hostname
  const creating_account = ref(false)

  const username = ref('')
  const password = ref('')
  const realname = ref('')
  const terms = ref(false)

  const validateEmail = email => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regex.test(email)
  }

  const register = async () => {
    if (username.value === '' || password.value === '' || realname.value === '' || terms.value === false) {
      notify.error('Please fill in all fields and accept Terms and Conditions.')
      return
    }

    if (!validateEmail(username.value)) {
      notify.error('Please enter a valid email address.')
      return
    }

    // for university emails, send them to this page https://forms.gle/zjeVkFigzmX1pXDv5
    if (username.value.includes('.edu')) {
      // open a new tab
      window.open('https://forms.gle/zjeVkFigzmX1pXDv5', '_blank')
    }

    try {
      // submit data in body
      const response = await axios.post(
        '/api/auth/register',
        {
          username: username.value,
          password: password.value,
          realname: realname.value,
          gclid,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )

      if (response.status === 200 || response.status === 201) {
        console.log('register successful')
        // store the  username in local storage
        localStorage.setItem('username', username.value)
        router.push('/verify')
      }
    } catch (error) {
      console.log(username.value)
      console.log(error)
      notify.error(error?.response?.data?.detail)
      console.log('login failed')
    }
  }

  onMounted(() => {})
</script>

<style></style>
