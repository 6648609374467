<template>
  <svg :class="classList ? classList : 'w-6 h-6'" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5708 0.981934H0.907471V12.3682H12.5708V0.981934Z" fill="#F25022"></path>
    <path d="M25.4625 0.981934H13.7992V12.3682H25.4625V0.981934Z" fill="#7FBA00"></path>
    <path d="M25.4629 13.5649H13.7996V24.9512H25.4629V13.5649Z" fill="#FFB900"></path>
    <path d="M12.5708 13.5649H0.907471V24.9512H12.5708V13.5649Z" fill="#00A4EF"></path>
  </svg>
</template>

<script>
  export default {
    name: 'OpenAIIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
