<template>
  <div ref="outerDiv" class="relative bg-white dark:bg-gray-900">
    <label v-if="fullscreen" for="underline_select" class="sr-only">Chart Type</label>
    <select
      v-if="fullscreen && ['bar', 'line', 'area', 'funnel', 'scatter'].includes(selectedChartType)"
      v-model="selectedChartType"
      @change="updateChartType"
      id="underline_select"
      class="block py-2.5 px-0 w-64 m-2 ml-16 mt-3 text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
    >
      <option value="bar">Bar Chart</option>
      <option value="line">Line Chart</option>
      <option value="area">Area Chart</option>
      <option value="funnel">Funnel Chart</option>
      <option value="scatter">Scatter Chart</option>
    </select>

    <!--    <button v-if="fullscreen" @click="toggleTextLabels">{{ showingText ? 'Hide' : 'Show' }} Text Labels</button>-->
    <!--full screen button-->
    <div class="m-auto" :style="this.fullscreen ? 'padding: 40px; padding-top: 10px' : ''">
      <div ref="plotlyDiv"></div>
    </div>
    <div class="top-0 right-0 absolute flex flex-col" :class="!this.fullscreen ? 'm-0' : 'm-6 mt-3'">
      <button
        v-tooltip="!this.fullscreen ? 'Full Screen' : 'Exit Full Screen'"
        class="opacity-60 bg-opacity-10 text-black dark:text-white hover:opacity-100 rounded-full p-1 hover:bg-blend-soft-light"
        @click="toggleFullScreen"
      >
        <svg
          v-if="!this.fullscreen"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <button
        v-tooltip="'Download Chart as .png'"
        class="opacity-60 bg-opacity-10 text-black dark:text-white hover:opacity-100 rounded-full p-1"
        @click="downloadPlot"
      >
        <svg
          v-if="!fullscreen"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
      </button>
      <button
        v-if="isZoomed"
        @click="resetZoom"
        v-tooltip="'Reset Zoom'"
        class="opacity-60 mt-1 bg-opacity-10 text-black dark:text-white hover:opacity-100 rounded-full p-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          data-slot="icon"
          :class="!fullscreen ? 'w-5 h-5' : 'w-6 h-6'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
  import Plotly from 'plotly.js-dist-min'
  import { useUserStore } from '@/stores/userStore'

  const renderChart = async function () {
    const userStore = useUserStore()
    if (this.chartConfig) {
      try {
        this.chartConfig.layout = this.chartConfig.layout || {}
        this.chartConfig.layout.yaxis = this.chartConfig.layout.yaxis || {}
        this.chartConfig.layout.xaxis = this.chartConfig.layout.xaxis || {}

        // this.chartConfig.layout.yaxis.fixedrange = true
        // if (this.chartConfig.layout.yaxis.range) {
        //   this.chartConfig.layout.yaxis.autorange = false
        // }
        this.chartConfig.layout.yaxis.automargin = true
        this.chartConfig.layout.xaxis.automargin = true
        this.chartConfig.layout.margin = { l: 50, r: 50, t: 80, b: 60 }
        this.chartConfig.layout.title = this.chartConfig.layout.title || {}
        this.chartConfig.layout.title.y = 0.95
        this.chartConfig.layout.title.x = 0.01

        if (userStore.isDarkMode && this.chartConfig.layout) {
          console.log('dark mode')
          console.log(this.chartConfig.layout)
          this.chartConfig.layout.plot_bgcolor = '#262626'
          this.chartConfig.layout.paper_bgcolor = '#262626'
          this.chartConfig.layout.template.layout.font.color = '#fff'
          this.chartConfig.layout.xaxis.tickcolor = '#727272'
          this.chartConfig.layout.yaxis.tickcolor = '#727272'
          this.chartConfig.layout.xaxis.gridcolor = '#252525'
          this.chartConfig.layout.yaxis.gridcolor = '#252525'
          this.chartConfig.layout.xaxis.zerolinecolor = '#313131'
          this.chartConfig.layout.yaxis.zerolinecolor = '#313131'
          // if there is yaxis2, hide the grid lines
          if (this.chartConfig.layout.yaxis2) {
            this.chartConfig.layout.yaxis2.gridcolor = 'rgba(0,0,0,0)'
          }
        } else {
          console.log('light mode')
          console.log(this.chartConfig.layout)
          this.chartConfig.layout.plot_bgcolor = '#fff'
          this.chartConfig.layout.paper_bgcolor = '#fff'
          this.chartConfig.layout.template.layout.font.color = '#000'
          this.chartConfig.layout.xaxis.tickcolor = '#727272'
          this.chartConfig.layout.yaxis.tickcolor = '#727272'
          this.chartConfig.layout.xaxis.gridcolor = '#f2f2f2'
          this.chartConfig.layout.yaxis.gridcolor = '#f2f2f2'
          this.chartConfig.layout.xaxis.zerolinecolor = '#d9d9d9'
          this.chartConfig.layout.yaxis.zerolinecolor = '#d9d9d9'
          // if there is yaxis2, hide the grid lines
          if (this.chartConfig.layout.yaxis2) {
            this.chartConfig.layout.yaxis2.gridcolor = 'rgba(0,0,0,0)'
          }
        }

        if (this.fullscreen) {
          this.chartConfig.layout.height = window.innerHeight - 100
        } else {
          this.chartConfig.layout.height = 450
        }

        // make tooltip font bigger and remove the border
        this.chartConfig.layout.hoverlabel = {
          font: {
            size: 14,
          },
          // bgcolor: 'rgba(0,0,0,0.85)',
          // bordercolor: 'rgba(0,0,0,0.3)',
        }

        // extract selectedChartType from the plotly config
        this.selectedChartType = this.chartConfig.data[0].type
        // line chart is scatter plot with mode lines
        if (this.selectedChartType === 'scatter' && this.chartConfig.data[0].mode === 'lines') {
          this.selectedChartType = 'line'
        }
        // area chart is scatter plot with fill to next y
        if (this.selectedChartType === 'scatter' && this.chartConfig.data[0].fill === 'tonexty') {
          this.selectedChartType = 'area'
        }
        console.log('selectedChartType', this.selectedChartType)

        // make the hovermode x unified for line charts and bar charts
        // this.chartConfig.layout.hovermode = 'x unified'
        Plotly.newPlot(this.$refs.plotlyDiv, this.chartConfig.data, this.chartConfig.layout, { displayModeBar: false })
        this.setupZoomListener()
      } catch (error) {
        console.error('Error rendering chart:', error)
      }
    }
  }

  export default {
    name: 'PlotlyChart',
    props: {
      chartConfig: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedChartType: 'bar',
        showingText: false,
        userStore: useUserStore(),
        fullscreen: false,
        isZoomed: false,
      }
    },
    mounted() {
      this.renderChart()
    },
    watch: {
      'userStore.isDarkMode': {
        handler: function () {
          if (this.chartConfig && this.$refs.plotlyDiv) {
            // re-render the chart when the parent element is visible
            if (this.$refs?.chartContainer?.closest('.chart_grand_parent').classList.contains('hidden')) return
            this.renderChart()
          }
        },
        immediate: true,
      },
      "this.$refs?.chartContainer?.closest('.chart_grand_parent').classList": {
        handler: function () {
          if (this.chartConfig && this.$refs.plotlyDiv) {
            // re-render the chart when the parent element is visible
            if (this.$refs?.chartContainer?.closest('.chart_grand_parent').classList.contains('hidden')) return
            this.renderChart()
          }
        },
        immediate: true,
      },
      // when we exit document fullscreen mode, we need to update the chart
      fullscreen: function () {
        if (!document.fullscreenElement) {
          this.renderChart()
          // this.toggleFullScreen()
        }
      },
    },
    methods: {
      renderChart,
      toggleFullScreen() {
        this.fullscreen = !this.fullscreen
        if (this.fullscreen) {
          // request fullscreen and when this is done, render the chart
          this.$refs.outerDiv.requestFullscreen().then(() => {
            this.renderChart()
          })
        } else {
          // exit fullscreen
          document.exitFullscreen().then(() => {
            this.renderChart()
          })
        }
      },
      downloadPlot() {
        // get plotly element with ref
        const plotElement = this.$refs.plotlyDiv
        // const plotElement = document.getElementById('myPlot');

        Plotly.toImage(plotElement, { format: 'png', width: 1100, height: 600 })
          .then(function (url) {
            var downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = 'plot.png'
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          })
          .catch(err => {
            console.error('Error downloading plot:', err)
          })
      },
      updateChartType() {
        let update_data = {
          type: this.selectedChartType,
          fill: 'none',
        }
        let update_layout = {}

        // Special handling for bar chart type
        if (this.selectedChartType === 'bar') {
          update_data = {
            type: 'bar',
          }
          update_layout = {
            barmode: 'stack',
          }
        }
        // Special handling for area chart type
        if (this.selectedChartType === 'area') {
          update_data = {
            fill: 'tonexty',
            type: 'scatter',
            stackgroup: 'one',
            mode: 'lines',
          }
        }
        // Special handling for line chart type
        if (this.selectedChartType === 'line') {
          update_data = {
            type: 'scatter',
            fill: 'none',
            mode: 'lines',
            stackgroup: null,
          }
        }
        if (this.selectedChartType === 'scatter') {
          update_data = {
            type: 'scatter',
            mode: 'markers',
            fill: 'none',
            stackgroup: null,
          }
        }
        // special handling for funnel chart type
        if (this.selectedChartType === 'funnel') {
          update_data = {
            type: 'funnel',
          }
        }

        // Apply updates to all traces
        Plotly.update(this.$refs.plotlyDiv, update_data, update_layout)
      },
      toggleTextLabels() {
        this.showingText = !this.showingText
        let update_data = {
          texttemplate: '%{value}',
        }
        let update_layout = {}

        if (this.showingText && this.selectedChartType === 'bar') {
          update_data = {
            texttemplate: '%{value}',
          }
        } else if (this.showingText && ['line', 'area'].includes(this.selectedChartType)) {
          update_data = {
            mode: 'lines+text',
          }
        }

        console.log('update_data', update_data)
        // Apply updates to all traces
        Plotly.update(this.$refs.plotlyDiv, update_data, update_layout)
      },
      setupZoomListener() {
        const plotDiv = this.$refs.plotlyDiv
        plotDiv.on('plotly_relayout', eventData => {
          if (eventData['xaxis.range[0]'] || eventData['yaxis.range[0]']) {
            this.isZoomed = true
            console.log('zoomed')
          }
        })
      },
      resetZoom() {
        Plotly.relayout(this.$refs.plotlyDiv, {
          'xaxis.autorange': true,
          'yaxis.autorange': true,
        })
        this.isZoomed = false
      },
    },
  }
</script>
