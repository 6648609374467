<template>
  <button
    class="primary-toggle"
    :class="{
      'toggle-parent-active': active,
      'toggle-parent-inactive': !active,
    }"
    @click="$emit('update:active', !active)"
  >
    <span
      class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
      :class="{
        'left-[1px]': !active,
        'right-[1px]': active,
      }"
    ></span>
  </button>
</template>

<script>
  export default {
    name: 'ToggleButton',
    props: {
      classList: {
        type: String,
        default: '',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },

    components: {},

    setup({}, { emit }) {
      return {
        emit,
      }
    },
  }
</script>
