<template>
  <ConnectionWrapper connection-name="Microsoft SQL Server" :connection-is-active="!!mutableConnection.last_sync">
    <template v-slot:icon>
      <MSSQLIcon classList="w-6 h-6" />
    </template>
    <div
      v-if="hasLastSync"
      v-tooltip="mutableConnection.last_sync"
      @click="emit('showLastLogs', 'mssql')"
      class="text-xs mb-2 text-gray-500 dark:text-gray-400 hover:underline cursor-pointer"
    >
      last synced {{ lastSyncDistanceString }}
    </div>
    <div>
      <div class="mb-6">
        <label for="mssql-server" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Host</label>
        <input
          v-model="mutableConnection.server"
          type="text"
          id="mssql-server"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="private_db.example.com"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mssql-port" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Port</label>
        <input
          v-model="mutableConnection.port"
          type="text"
          id="mssql-port"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="5432"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mssql-username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Username
        </label>
        <input
          v-model="mutableConnection.username"
          type="text"
          id="mssql-username"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="DOT"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mssql-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Password
        </label>
        <input
          v-model="mutableConnection.password"
          type="password"
          id="mssql-password"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mssql-database" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Database
        </label>
        <input
          v-model="mutableConnection.database"
          type="text"
          id="mssql-database"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="PROD_DB"
        />
      </div>
      <button
        v-if="editMode"
        @click="editMode = false"
        class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2"
      >
        Cancel
      </button>
      <button
        v-if="hasLastSync && editMode"
        @click.prevent="
          emit('showRemoveModal', {
            ...mutableConnection,
            ...{
              title: mutableConnection.server,
            },
          })
        "
        class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800 mr-2"
      >
        <span>Remove</span>
      </button>
      <button
        @click.prevent="connect"
        v-if="computedEditMode"
        :disabled="isLoading || !requiredFieldsFilled"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:bg-gray-500 disabled:dark:bg-gray-600"
      >
        <LoadingIcon v-if="isLoading" class="inline w-4 h-4 mr-3 text-white animate-spin" />
        <span v-if="isLoading">Loading...</span>
        <span v-else>Connect</span>
      </button>
      <button
        v-if="!computedEditMode"
        @click.stop.prevent="editMode = true"
        class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2"
      >
        Edit
      </button>
      <button
        @click.prevent="sync"
        v-if="hasLastSync && !computedEditMode"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <LoadingIcon class="inline w-4 h-4 mr-3 text-white animate-spin" v-if="isLoading" />
        <span v-if="isLoading">Loading...</span>
        <span v-else>Sync</span>
      </button>
    </div>
  </ConnectionWrapper>
</template>

<script setup>
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import { defineProps, defineEmits, ref, computed, watch } from 'vue'
  import { formatDistanceToNow } from 'date-fns'
  import MSSQLIcon from '@/components/icons/MSSQLIcon.vue'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const orgStore = useOrgStore()

  const editMode = ref(false)
  const isLoading = ref(false)

  const emit = defineEmits(['update:modelValue', 'showLastLogs', 'showRemoveModal', 'updateConnections'])

  const props = defineProps({
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    sync_connection: {
      type: Function,
      required: true,
    },
  })

  const mutableConnection = computed({
    get: () => props.modelValue,
    set: value => {
      emit('update:modelValue', value)
    },
  })

  const computedEditMode = computed(() => !mutableConnection.value.last_sync || editMode.value)
  const hasLastSync = computed(() => mutableConnection.value.last_sync)
  const lastSyncDistanceString = computed(() => {
    if (mutableConnection.value.last_sync && mutableConnection.value.last_sync.includes('+')) {
      return formatDistanceToNow(new Date(mutableConnection.value.last_sync), { addSuffix: true })
    } else {
      return ''
    }
  })

  const requiredFieldsFilled = computed(() => {
    return (
      mutableConnection.value.server &&
      mutableConnection.value.port &&
      mutableConnection.value.username &&
      mutableConnection.value.password &&
      mutableConnection.value.database
    )
  })

  const connect = async () => {
    if (!requiredFieldsFilled.value) {
      notify.error('Please fill in all fields')
      return
    }

    isLoading.value = true

    try {
      let response = await axios.post(`/api/connect_mssql`, mutableConnection.value)
      emit('updateConnections')

      notify.success(response.data)
      orgStore.getConnections(true)

      await props.sync_connection('mssql')

      editMode.value = false
    } catch (error) {
      console.error(error)
      notify.error(error?.response?.data?.detail || 'An error occurred')
    } finally {
      isLoading.value = false
    }
  }

  const sync = async () => {
    isLoading.value = true

    await props.sync_connection('mssql')

    isLoading.value = false
  }
</script>
