<template>
  <div class="fixed top-4 flex flex-col items-center justify-center end-6 group" style="z-index: 3">
    <button
      @click="toggleTheme"
      type="button"
      aria-expanded="false"
      class="flex items-center justify-center ml-auto text-black dark:text-white rounded-full w-8 h-8 hover:bg-gray-100 dark:hover:bg-gray-900 focus:ring-4 focus:ring-gray-50 focus:outline-none dark:focus:ring-gray-950"
    >
      <svg
        v-if="isDarkMode"
        class="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
        />
      </svg>
      <svg
        v-else
        class="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
  import { onMounted, computed } from 'vue'
  import { useUserStore } from '@/stores/userStore'

  export default {
    name: 'ThemeToggle',
    setup() {
      const userStore = useUserStore()

      function isRunningInIframe() {
        try {
          return window.self !== window.top
        } catch (e) {
          return true
        }
      }

      const uiMode = computed(() => {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get('uiMode')
      })

      const isDarkMode = computed(() => {
        return userStore.isDarkMode
      })

      function toggleTheme() {
        userStore.isDarkMode = !userStore.isDarkMode
        if (userStore.isDarkMode) {
          document.querySelector('html').classList.add('dark')
          localStorage.setItem('color-theme', 'dark')
        } else {
          document.querySelector('html').classList.remove('dark')
          localStorage.setItem('color-theme', 'light')
        }
      }

      // initialize components based on data attribute selectors
      onMounted(() => {
        if (isRunningInIframe() && uiMode.value === 'dark') {
          userStore.isDarkMode = true
          document.querySelector('html').classList.add('dark')
          localStorage.setItem('color-theme', 'dark')
        } else if (isRunningInIframe() && uiMode.value === 'light') {
          userStore.isDarkMode = false
          document.querySelector('html').classList.remove('dark')
          localStorage.setItem('color-theme', 'light')
        } else if (
          localStorage.getItem('color-theme') === 'dark' ||
          (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
          userStore.isDarkMode = true
          document.querySelector('html').classList.add('dark')
        } else {
          userStore.isDarkMode = false
          document.querySelector('html').classList.remove('dark')
        }
      })

      return {
        toggleTheme,
        isDarkMode,
      }
    },
  }
</script>
