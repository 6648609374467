<template>
  <div class="py-4" id="profile" role="tabpanel" aria-labelledby="connection-tab">
    <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Connect with your data stack.</h3>
    <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
      Connect with your data stack. Only grant read permissions to data sources Dot should use for answering questions.
      <a
        href="https://docs.getdot.ai/dot/integrations"
        target="_blank"
        class="text-blue-600 dark:text-blue-300 hover:underline"
      >
        Read how to connect.
      </a>
    </p>

    <h4 class="text-md font-medium text-gray-900 dark:text-white text-left mt-9">Databases</h4>
    <div class="flex flex-row flex-wrap gap-4 mt-2">
      <SnowflakeConnection
        v-model="snowflake"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <BigqueryConnection
        v-model="bigquery"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <RedshiftConnection
        v-model="redshift"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <PostgresConnection
        v-model="postgres"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <DatabricksConnection
        v-model="databricks"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <MSSQLConnection
        v-model="mssql"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <MySQLConnection
        v-model="mysql"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <SAPHanaConnection
        v-model="saphana"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <DuckDBConnection
        v-model="duckdb"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <UsageConnection
        v-model="usagedb"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <FileUploader />

      <!-- Active Plugins -->

      <ConnectionWrapper
        v-for="plugin in active_plugins"
        :key="plugin.org_id"
        :connection-name="plugin.title"
        :connection-is-active="true"
      >
        <div class="mb-6">
          <label v-dompurify-html="plugin.description" />
        </div>
        <button
          @click.prevent="removePlugin(plugin)"
          class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          <span>Remove</span>
        </button>
      </ConnectionWrapper>
    </div>

    <h4 class="text-md font-medium text-gray-900 dark:text-white text-left mt-9">Semantic Layers</h4>
    <div class="flex flex-row flex-wrap gap-4 mt-2">
      <LookerConnection
        v-model="looker"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />

      <DBTConnection
        v-model="dbt"
        :sync_connection="sync_connection"
        @show-remove-modal="showRemoveModal($event)"
        @show-last-logs="showLastLogs(`init_${$event}`)"
        @update-connections="initiateInfo"
      />
    </div>

    <h4 class="text-md font-medium text-gray-900 dark:text-white text-left mt-9">Communication</h4>
    <div class="flex flex-row flex-wrap gap-4 mt-2">
      <!-- slack button previous url              href="https://slack.com/oauth/v2/authorize?client_id=4824287846656.5021108218019&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.customize,commands,emoji:read,files:read,files:write,im:history,im:read,im:write,reactions:read,reactions:write,team:read&user_scope="> -->
      <!-- show current host name -->
      <ConnectionWrapper
        connection-name="Slack"
        :connection-is-active="this.slack_team_id && this.slack_team_id.length > 0"
      >
        <template v-slot:icon>
          <SlackIcon classList="w-6 h-6" />
        </template>

        <label for="slack-add-button" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          1. Register App
        </label>
        <a
          target="_blank"
          href="/slack/install"
          id="slack-add-button"
          class="block text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2 mb-3 mt-1 border border-gray-100 dark:border-gray-800"
        >
          Add Dot to Slack
        </a>

        <div class="mb-4">
          <label for="slack-team-id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            2. Slack Team ID
          </label>
          <input
            type="text"
            id="slack-team-id"
            autocomplete="off"
            v-model="this.slack_team_id"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="T01A23BCDEF"
          />
        </div>
        <div class="mb-6">
          <!-- A flowbite toggle to choose between password and key-pair auth -->
          <label class="relative inline-flex items-center cursor-pointer">
            <span class="relative">
              <input type="checkbox" v-model="this.slack_auto_reply" class="sr-only peer" />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
              ></div>
            </span>
            <span class="ms-3 text-sm text-gray-900 dark:text-gray-300">Autoreply in Threads</span>
          </label>
        </div>
        <button
          @click.prevent="save_slack_team_id"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <span>Save</span>
        </button>
      </ConnectionWrapper>

      <!-- Teams -->
      <ConnectionWrapper
        connection-name="Teams"
        :connection-is-active="this.teams_tenant_id && this.teams_tenant_id.length > 0"
      >
        <template v-slot:icon>
          <TeamsIcon classList="w-6 h-6" />
        </template>

        <label for="teams-add-button" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          1. Add App to your organization
        </label>
        <a
          target="_blank"
          href="/api/teams/install"
          id="teams-add-button"
          class="block text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800 mr-2 mb-3 mt-1 border border-gray-100 dark:border-gray-800"
        >
          Add Dot to Teams
        </a>

        <div class="mb-6">
          <label for="teams-tenant-id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            2. Teams Tenant ID
          </label>
          <input
            type="text"
            id="teams-tenant-id"
            autocomplete="off"
            v-model="this.teams_tenant_id"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="abcdef12-3456-7890-abcd-123456789abc"
          />
        </div>
        <button
          @click.prevent="save_teams_tenant_id"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <span>Save</span>
        </button>
      </ConnectionWrapper>
    </div>

    <h4 class="text-md font-medium text-gray-900 dark:text-white text-left mt-9">Intelligence</h4>

    <div class="flex flex-row flex-wrap gap-4 mt-2">
      <!-- openai -->

      <ConnectionWrapper
        :connection-name="
          'Provider: ' +
          (llm_model && llm_model.startsWith('azure')
            ? 'Microsoft'
            : llm_model && llm_model.startsWith('claude')
              ? 'Anthropic'
              : 'OpenAI') +
          ''
        "
        :connection-is-active="false"
      >
        <template v-slot:icon>
          <AzureIcon v-if="llm_model && llm_model.startsWith('azure')" classList="w-6 h-6 dark:fill-white" />
          <AnthropicIcon v-else-if="llm_model && llm_model.startsWith('claude')" classList="w-6 h-6 dark:fill-white" />
          <OpenAIIcon v-else classList="w-6 h-6 dark:fill-white" />
        </template>

        <form>
          <div class="mb-6">
            <label for="llm-model" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Large Language Models
            </label>
            <select
              @change="change_model"
              v-model="llm_model"
              id="llm-model"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="auto">auto</option>
              <option value="openai">OpenAI</option>
              <option value="azure">Microsoft OpenAI</option>
              <option value="claude">Anthropic</option>
            </select>
          </div>
        </form>
      </ConnectionWrapper>

      <div class="grow"></div>
    </div>

    <h4 class="text-md font-medium text-gray-900 dark:text-white text-left mt-9">Authentication</h4>

    <div class="flex flex-row flex-wrap gap-4 mt-2">
      <!-- okta -->

      <ConnectionWrapper
        connection-name="Okta"
        :connection-is-active="okta.client_id && okta.metadata_url && okta.metadata_url.length > 0"
      >
        <template v-slot:icon>
          <OktaIcon classList="w-6 h-6 dark:fill-white" />
        </template>

        <form>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Redirect URI
            </label>
            <input
              type="text"
              id="okta-redirect-uri"
              :value="`${baseURI}/api/auth/${org.id}/okta/auth`"
              disabled
              autocomplete="off"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client ID</label>
            <input
              type="text"
              id="okta-client-id"
              v-model="okta.client_id"
              autocomplete="off"
              :class="okta.client_id ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0oa1a2b3c4d5e6f7g8h9i"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Client Secret
            </label>
            <input
              type="password"
              id="okta-client-secret"
              v-model="okta.client_secret"
              autocomplete="off"
              :class="okta.client_secret ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="1Zp3Xw7Qk8GyLs9Vz4FbJnR6HdUcM2oEiW5qT8PX0YrANzBCDEfa9IlmvxoS3gHt"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Metadata URL
            </label>
            <input
              type="text"
              id="okta-metadata-url"
              v-model="okta.metadata_url"
              autocomplete="off"
              :class="okta.metadata_url ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="https://dev-12345678-admin.okta.com/.well-known/openid-configuration"
              required
            />
          </div>

          <button
            v-if="org.okta_active"
            @click.prevent="remove_sso('okta')"
            class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800 mr-2"
          >
            <span>Remove</span>
          </button>
          <button
            @click.prevent="save_sso('okta')"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <span>Save</span>
          </button>
        </form>
      </ConnectionWrapper>

      <!-- azure ad -->

      <ConnectionWrapper
        connection-name="Azure Entra ID"
        :connection-is-active="azure.client_id && azure.metadata_url && azure.metadata_url.length > 0"
      >
        <template v-slot:icon>
          <AzureEntraIcon classList="w-6 h-6 dark:fill-white" />
        </template>

        <form>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Redirect URI
            </label>
            <input
              type="text"
              id="entra-redirect-uri"
              :value="`${baseURI}/api/auth/${org.id}/azure/auth`"
              disabled
              autocomplete="off"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Client ID</label>
            <input
              type="text"
              id="azure-client-id"
              v-model="azure.client_id"
              autocomplete="off"
              :class="azure.client_id ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="1a2b3c4d-5e6f-7g8h-9i1j-2k3l4m5n6o7p"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Client Secret
            </label>
            <input
              type="password"
              id="azure-client-secret"
              v-model="azure.client_secret"
              autocomplete="off"
              :class="azure.client_secret ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="ab1~cdE2FgHi3JkLm4NOpQ~Rs5TUVw6XyZ~.7xYz8A"
              required
            />
          </div>
          <div class="mb-6">
            <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Metadata URL
            </label>
            <input
              type="text"
              id="azure-metadata-url"
              v-model="azure.metadata_url"
              autocomplete="off"
              :class="azure.metadata_url ? 'opacity-50' : ''"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="https://login.microsoftonline.com/12345678-9abc-def0-1234-56789abcdef0/v2.0/.well-known/openid-configuration"
              required
            />
          </div>

          <button
            v-if="org.azure_active"
            @click.prevent="remove_sso('azure')"
            class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800 mr-2"
          >
            <span>Remove</span>
          </button>
          <button
            @click.prevent="save_sso('azure')"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <span>Save</span>
          </button>
        </form>
      </ConnectionWrapper>

      <div class="grow"></div>
    </div>

    <!-- bottom drawer for logs -->

    <button
      id="show_log_drawer_button"
      class="hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      type="button"
      data-drawer-target="drawer-bottom-example"
      data-drawer-show="drawer-bottom-example"
      data-drawer-placement="bottom"
      aria-controls="drawer-bottom-example"
      data-drawer-backdrop="false"
    >
      Show bottom drawer
    </button>

    <!-- drawer component -->
    <div
      id="drawer-bottom-example"
      class="fixed shadow-lg bottom-0 cursor-pointer right-0 z-40 w-9/12 m-4 rounded-xl p-4 overflow-y-auto transition-transform bg-gray-100 dark:bg-gray-800 translate-y-full ml-auto"
      tabindex="-1"
      aria-labelledby="drawer-bottom-label"
      data-drawer-target="drawer-bottom-example"
      data-drawer-show="drawer-bottom-example"
    >
      <h5
        id="drawer-bottom-label"
        class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
      >
        <svg
          class="w-5 h-5 mr-2"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          v-if="!log_loading"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <svg
          v-else
          aria-hidden="true"
          class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>

        Sync Logs
      </h5>
      <button
        @click.stop=""
        type="button"
        data-drawer-hide="drawer-bottom-example"
        aria-controls="drawer-bottom-example"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Close menu</span>
      </button>
      <pre class="text-xs font-mono text-gray-500 dark:text-gray-400">{{ logs }}</pre>
      <button
        v-if="!log_loading"
        @click.prevent="$router.push('model')"
        class="primary-btn flex items-center gap-2 mt-4"
      >
        <span>Model your data</span>
        <Icon icon="ic:sharp-arrow-right-alt" class="w-6 h-6" />
      </button>
    </div>
  </div>

  <!-- Remove modal -->
  <div
    v-if="toBeRemoved"
    id="removeModal"
    ref="removeModal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 px-6 border-b rounded-t dark:border-gray-700">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Removing connection {{ toBeRemoved.title }}
          </h3>
          <button
            type="button"
            @click="hideRemoveModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="removeModal"
          >
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Removing this connection will also remove all associated models and queries. Are you sure you want to remove
            this connection?
          </p>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
          <button
            @click="hideRemoveModal"
            data-modal-hide="removeModal"
            type="button"
            class="ml-auto text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-xl border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="remove_connection(toBeRemoved)"
            :disabled="removingConnection"
            class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-gray-700 dark:bg-gray-800 dark:focus:ring-red-800 mr-2"
          >
            <LoadingIcon class="inline w-4 h-4 mr-3 text-white animate-spin" v-if="removingConnection" />
            <span>Remove</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import { initDrawers, initModals, Modal } from 'flowbite'
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import SnowflakeIcon from '@/components/icons/SnowflakeIcon.vue'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import SlackIcon from '@/components/icons/SlackIcon.vue'
  import TeamsIcon from '@/components/icons/TeamsIcon.vue'
  import OpenAIIcon from '@/components/icons/OpenAIIcon.vue'
  import AzureIcon from '@/components/icons/AzureIcon.vue'
  import OktaIcon from '@/components/icons/OktaIcon.vue'
  import AzureEntraIcon from '@/components/icons/AzureEntraIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { formatDistanceToNow } from 'date-fns'
  import AnthropicIcon from '@/components/icons/AnthropicIcon.vue'
  import SAPHanaIcon from '@/components/icons/SAPHanaIcon.vue'

  import SnowflakeConnection from '@/components/connectionsTab/SnowflakeConnection.vue'
  import BigqueryConnection from '@/components/connectionsTab/BigqueryConnection.vue'
  import RedshiftConnection from '@/components/connectionsTab/RedshiftConnection.vue'
  import PostgresConnection from '@/components/connectionsTab/PostgresConnection.vue'
  import DatabricksConnection from '@/components/connectionsTab/DatabricksConnection.vue'
  import MSSQLConnection from '@/components/connectionsTab/MSSQLConnection.vue'
  import MySQLConnection from '@/components/connectionsTab/MySQLConnection.vue'
  import LookerConnection from '@/components/connectionsTab/LookerConnection.vue'
  import DBTConnection from '@/components/connectionsTab/DBTConnection.vue'
  import SAPHanaConnection from '@/components/connectionsTab/SAPHanaConnection.vue'
  import DuckDBConnection from '@/components/connectionsTab/DuckDBConnection.vue'
  import UsageConnection from '@/components/connectionsTab/UsageConnection.vue'
  import FileUploader from '@/components/connectionsTab/FileUploader.vue'
  import { Icon } from '@iconify/vue'

  export default {
    components: {
      AnthropicIcon,
      ConnectionWrapper,
      SnowflakeIcon,
      LoadingIcon,
      SlackIcon,
      TeamsIcon,
      OpenAIIcon,
      AzureIcon,
      OktaIcon,
      AzureEntraIcon,
      SAPHanaIcon,
      SnowflakeConnection,
      BigqueryConnection,
      RedshiftConnection,
      PostgresConnection,
      DatabricksConnection,
      MSSQLConnection,
      MySQLConnection,
      LookerConnection,
      DBTConnection,
      SAPHanaConnection,
      DuckDBConnection,
      UsageConnection,
      FileUploader,
      Icon,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const userStore = useUserStore()

      const { user, modelMessage } = storeToRefs(userStore)

      const orgStore = useOrgStore()

      const { org } = storeToRefs(orgStore)

      const baseURI = window.location.origin

      return {
        user,
        modelMessage,
        org,
        getOrg: orgStore.getOrg,
        getConnections: orgStore.getConnections,
        baseURI,
        notify,
      }
    },
    mounted() {
      document.title = 'Connections · Settings · Dot'
      this.$nextTick(function () {
        initDrawers()
        initModals()
      }) // wait for DOM to update
    },
    created() {
      this.initiateInfo()
    },
    data() {
      return {
        log_loading: false,
        active_plugins: [],
        snowflake: {},
        bigquery: {},
        redshift: {},
        postgres: {},
        duckdb: {},
        usagedb: {},
        databricks: {},
        mssql: {},
        mysql: {},
        looker: {},
        dbt: {},
        saphana: {},
        slack_team_id: null,
        slack_auto_reply: true,
        teams_tenant_id: null,
        logs: 'loading ...',
        llm_model: 'auto',
        message: '',
        isSuccess: false,
        toBeRemoved: null,
        removeModal: null,
        removingConnection: false,
        okta: {
          client_id: null,
          client_secret: null,
          metadata_url: null,
        },
        azure: {
          client_id: null,
          client_secret: null,
          metadata_url: null,
        },
      }
    },
    methods: {
      formatDistanceToNow,
      initiateInfo() {
        this.snowflake = {}
        this.bigquery = {}
        this.redshift = {}
        this.postgres = {}
        this.duckdb = {}
        this.usagedb = {}
        this.databricks = {}
        this.looker = {}
        this.dbt = {}
        this.mssql = {}
        this.mysql = {}
        this.okta = {}
        this.azure = {}
        this.saphana = {}

        axios
          .get('/api/connections', { withCredentials: true })
          .then(response => {
            if (response.data) {
              for (const connection of response.data) {
                if (connection.type === 'snowflake') {
                  this.snowflake = connection
                } else if (connection.type === 'bigquery') {
                  this.bigquery = connection
                } else if (connection.type === 'redshift') {
                  this.redshift = connection
                } else if (connection.type === 'postgres') {
                  this.postgres = connection
                } else if (connection.type === 'duckdb') {
                  this.duckdb = connection
                } else if (connection.type === 'usagedb') {
                  this.usagedb = connection
                } else if (connection.type === 'databricks') {
                  this.databricks = connection
                } else if (connection.type === 'looker') {
                  this.looker = connection
                } else if (connection.type === 'dbt') {
                  this.dbt = connection
                } else if (connection.type === 'plugin') {
                  this.active_plugins.push(connection)
                } else if (connection.type === 'mssql') {
                  this.mssql = connection
                } else if (connection.type === 'saphana') {
                  this.saphana = connection
                } else if (connection.type === 'mysql') {
                  this.mysql = connection
                }
              }
            }
          })
          .catch(error => {
            console.log(error)
          })

        // Get org information from the API using axios
        axios
          .get('/api/org')
          .then(response => {
            this.slack_team_id = response.data.slack_team_id
            this.slack_auto_reply = response.data?.slack_auto_reply ?? true
            this.teams_tenant_id = response.data.teams_tenant_id
            this.llm_model = response.data.llm_model || 'auto'
            this.okta = {
              client_id: response.data.okta_client_id,
              client_secret: response.data.okta_client_secret,
              metadata_url: response.data.okta_metadata_url,
            }
            this.azure = {
              client_id: response.data.azure_client_id,
              client_secret: response.data.azure_client_secret,
              metadata_url: response.data.azure_metadata_url,
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      async remove_connection(integration) {
        try {
          this.removingConnection = true
          let response = await axios.post(`/api/remove_connection`, {
            integration,
          })
          if (response.status === 200) {
            this.notify.success('Connection removed successfully.')

            integration = {}

            this.$nextTick(function () {
              this.initiateInfo()
              this.getConnections(true)
            })
          }
          this.hideRemoveModal()
          this.removingConnection = false
        } catch (error) {
          console.log(error)
          this.notify.error(error)
          this.removingConnection = false
        }
      },
      async save_slack_team_id() {
        let slack_team_id = document.getElementById('slack-team-id').value

        if (slack_team_id === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        try {
          let response = await axios.post(`/api/save_slack_team_id`, {
            slack_team_id: slack_team_id,
            slack_auto_reply: this.slack_auto_reply,
          })
          console.log(response.data)
          this.slack_team_id = slack_team_id
          this.notify.success('Saved successfully.')
        } catch (error) {
          console.log(error)
          console.log(error.response.data)
          this.notify.error(error.response.data.detail)
          this.message = error.response.data.detail
        }
        console.log(this.message)
      },
      async save_teams_tenant_id() {
        let teams_tenant_id = document.getElementById('teams-tenant-id').value

        if (teams_tenant_id === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        try {
          let response = await axios.post(`/api/save_teams_tenant_id`, {
            teams_tenant_id: teams_tenant_id,
          })
          console.log(response.data)
          this.teams_tenant_id = teams_tenant_id
          this.notify.success('Saved successfully.')
        } catch (error) {
          console.log(error.response.data)
          this.notify.error(error.response.data.detail)
          this.message = error.response.data.detail
        }
        console.log(this.message)
      },
      async save_sso(type) {
        let client_id = this[type].client_id
        let client_secret = this[type].client_secret
        let metadata_url = this[type].metadata_url

        if (!client_id || !client_secret || !metadata_url) {
          this.notify.error('Please fill in all fields')
          return
        }

        try {
          let response = await axios.post(`/api/save_sso`, {
            type: type,
            client_id: client_id,
            client_secret: client_secret,
            metadata_url: metadata_url,
          })
          console.log(response.data)
          this.getOrg()
          this[type] = {
            client_id: client_id,
            client_secret: client_secret,
            metadata_url: metadata_url,
          }
          this.notify.success('Saved successfully.')
        } catch (error) {
          console.log(error.response.data)
          this.notify.error(error.response.data.detail)
        }
      },
      async remove_sso(type) {
        try {
          let response = await axios.post(`/api/remove_sso`, {
            type: type,
          })
          console.log(response.data)
          this.getOrg()
          this[type] = {}
          this.notify.success('Removed successfully.')
        } catch (error) {
          console.log(error.response.data)
          this.notify.error(error.response.data.detail)
        }
      },
      async change_model() {
        let llm_model = document.getElementById('llm-model').value
        try {
          let response = await axios.post(`/api/change_model`, {
            llm_model: llm_model,
          })
          console.log(response.data)
          this.llm_model = llm_model
          this.message = 'Model changed successfully to ' + llm_model + '.'
          this.notify.success(this.message)
        } catch (error) {
          console.log(error)
          this.notify.error(error)
        }
      },

      async sync_connection(type) {
        return new Promise(async (resolve, reject) => {
          this.log_loading = true
          try {
            const response = await axios.post(`/api/init/${type}`)
            console.log(response.data)
            console.log(`sync_${type} started`)
            await this.startFetchingLogs(`init_${type}`, `loading_sync_${type}`)
            this.notify.success(`Connection was successfully connected and synced.<br>
On the "Model" page you can now choose the information that should be used by Dot.`)

            this.log_loading = false

            this.$nextTick(() => {
              this.initiateInfo()
            })

            resolve()
          } catch (error) {
            console.log(error)
            reject(error)
          }
        })
      },

      async startFetchingLogs(endpoint, loading_var) {
        document.getElementById('show_log_drawer_button').click()

        return new Promise(async (resolve, reject) => {
          try {
            await new Promise(r => setTimeout(r, 110))
            let finished = false
            // terminate loop after 5 minutes or when finished is true or when no new message is received for 30 seconds
            let start_time = Date.now()
            const last_logs = []
            while (!finished && Date.now() - start_time < 15 * 60 * 1000 && last_logs.length < 10) {
              let response = await axios.get(`/api/logs/${endpoint}`)
              this.logs = response.data.log_contents
              finished = response.data.finished
              await new Promise(r => setTimeout(r, 2000))
            }
          } catch (error) {
            console.log(error)
            reject(error)
          }
          console.log(`${endpoint} finished`)
          resolve()
        })
      },
      async showLastLogs(endpoint) {
        document.getElementById('show_log_drawer_button').click()
        try {
          let response1 = await axios.get(`/api/logs/${endpoint}`)
          console.log(response1.data)
          this.logs = response1.data.log_contents
          console.log(`${endpoint} finished`)
          console.log(this.logs)
        } catch (error) {
          console.log(error)
        }
      },
      removePlugin(plugin) {
        console.log(plugin)
        axios
          .post(
            '/api/deactivate_plugin',
            {
              plugin_id: plugin.org_id,
            },
            { withCredentials: true }
          )
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.active_plugins = this.active_plugins.filter(p => p !== plugin)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      showRemoveModal(connection) {
        this.toBeRemoved = connection
        this.$nextTick(() => {
          this.removeModal = new Modal(this.$refs['removeModal'])
          this.removeModal.show()
        })
      },
      hideRemoveModal() {
        this.removeModal.hide()
        this.toBeRemoved = null
      },
    },
  }
</script>
