import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axiosInstance'

export const useGlobalStore = defineStore('global', () => {
  const showNoty = ref(false)
  const triggerNoty = ref(1)
  const notyMessage = ref('')
  const isSuccess = ref(true)

  const subscription_loaded = ref(false)
  const subscription_status = ref(false)
  const subscription_price = ref(500)
  const subscription_currency = ref('usd')

  const notify = {
    success: message => {
      showNoty.value = true
      triggerNoty.value = triggerNoty.value + 1
      isSuccess.value = true
      notyMessage.value = typeof message === 'string' ? message : 'Success!'
    },
    error: message => {
      showNoty.value = true
      triggerNoty.value = triggerNoty.value + 1
      isSuccess.value = false
      notyMessage.value = typeof message === 'string' ? message : 'Something went wrong!'
    },
    info: message => {
      showNoty.value = true
      triggerNoty.value = triggerNoty.value + 1
      isSuccess.value = true
      notyMessage.value = typeof message === 'string' ? message : 'Info!'
    },
  }

  const getSubscriptionStatusFromServer = () => {
    if (subscription_loaded.value) {
      return
    }

    axios.get('/api/get_subscription_status', { withCredentials: true }).then(response => {
      if (response.data) {
        if (response.data.status === 'active') {
          subscription_status.value = true
        } else {
          subscription_status.value = false
        }

        if (response.data.price) {
          subscription_price.value = response.data.price / 100
        }

        if (response.data.currency) {
          subscription_currency.value = response.data.currency
        }

        subscription_loaded.value = true
      }
    })
  }

  return {
    showNoty,
    triggerNoty,
    notyMessage,
    isSuccess,
    notify,
    subscription_status,
    subscription_price,
    subscription_currency,
    getSubscriptionStatusFromServer,
  }
})
