<template>
  <section class="bg-white dark:bg-gray-900 w-full">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div
        class="w-full bg-white rounded-2xl border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-900 dark:border-gray-700"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Sign in
          </h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="login">
            <button
              type="button"
              v-if="org && org.okta_active"
              id="sso-okta-button"
              @click.prevent="login_with_sso('okta')"
              class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
            >
              Sign in with Okta
            </button>
            <button
              type="button"
              v-if="org && org.azure_active"
              id="sso-azure-button"
              @click.prevent="login_with_sso('azure')"
              class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
            >
              Sign in with Azure
            </button>

            <hr v-if="!firstStep && (org.okta_active || org.azure_active) && !org.disable_password_login" />

            <div v-show="firstStep || !org.disable_password_login">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
                @keyup.enter="getOrganizationFromEmail"
              />
            </div>
            <div v-show="!firstStep && !org.disable_password_login">
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                @keyup.enter="login"
              />
            </div>
            <div v-if="!firstStep && !org.disable_password_login" class="flex items-center justify-between">
              <router-link to="/password" class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                Forgot password?
              </router-link>
            </div>
            <button
              type="button"
              v-if="firstStep"
              id="continue-button"
              @click="getOrganizationFromEmail"
              class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
            >
              Continue
            </button>
            <button
              type="submit"
              v-if="!firstStep && !org.disable_password_login"
              id="login-button"
              @click="login"
              class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
            >
              Sign in
            </button>
            <p v-if="firstStep" class="text-sm text-gray-400 dark:text-gray-500">
              <!--              Forgot your password? <router-link to="/password"-->
              <!--                                            class="font-medium text-blue-600 hover:underline dark:text-blue-500">Reset password</router-link>-->
              <!--              <br />-->
              Don’t have an account yet?
              <router-link to="/register" class="font-medium text-blue-600 hover:underline dark:text-blue-500">
                Sign up
              </router-link>
              <br />
              <!-- show the following if the url does not start with eu.getdot.ai -->
              <span
                v-if="window ? window.location.hostname !== 'eu.getdot.ai' : true"
                class="text-sm mt-0 text-gray-400 dark:text-gray-500"
              >
                Region: US
                <a
                  href="https://eu.getdot.ai/login"
                  class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Switch to EU
                </a>
              </span>
              <span v-else class="text-sm mt-0 text-gray-400 dark:text-gray-500">
                Region: EU
                <a
                  href="https://app.getdot.ai/login"
                  class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Switch to US
                </a>
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import axios from 'axios'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import { useGlobalStore } from './stores/globalStore'

  // store login state in local storage
  // https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  export default {
    name: 'LoginPage',
    setup() {
      const orgStore = useOrgStore()
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const firstStep = ref(true)
      const email = ref('')
      const org = ref({})

      const getOrganizationFromEmail = async () => {
        if (email.value === '') {
          return
        }

        org.value = await orgStore.getOrganizationFromEmail(email.value)

        firstStep.value = false

        if (org.value.disable_password_login) {
          // if password login is disabled, login with sso, if multiple sso are enabled, skip this step
          if (org.value.okta_active && org.value.azure_active) {
            return
          } else if (org.value.okta_active) {
            login_with_sso('okta')
          } else if (org.value.azure_active) {
            login_with_sso('azure')
          }
        }
      }

      onMounted(() => {})

      return {
        email,
        firstStep,
        org,
        getOrganizationFromEmail,
        notify,
      }
    },
    data() {
      return {
        window,
        message: '',
        isSuccess: true,
      }
    },
    methods: {
      async login() {
        // get inputs fields
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value

        if (email === '' || password === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // create a new FormData object and append email and password to it
        const formData = new FormData()
        formData.append('username', email)
        formData.append('password', password)

        // call /auth/token login api with email and password using axios
        try {
          const response = await axios.post('/api/auth/token', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            // store login state in local storage
            localStorage.setItem('login', JSON.stringify({ email }))
            this.$router.push('/')
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
          console.log('login failed')

          // if status is 403 redirect to email verification page
          if (error.response.status === 403) {
            this.message = 'Please verify your email. Redirecting in 3 seconds...'
            this.notify.success(this.message)
            // wait 3 seconds before redirecting to email verification page
            await new Promise(resolve => setTimeout(resolve, 3000))

            this.$router.push('/verify?username=' + email)
          }
        }
      },
      login_with_sso(type) {
        const org_id = this.org.id
        window.location.href = `/api/auth/${org_id}/${type}/login`
      },
    },
  }
</script>

<style></style>
