const isSupportedFileType = file => {
  const supportedTypes = [
    'text/csv',
    'application/csv',
    'application/excel',
    'application/vnd.ms-excel',
    'application/vnd.msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.google-apps.spreadsheet',
    'text/plain',
  ]

  return supportedTypes.includes(file.type)
}

function isValidFileType(file, notify) {
  const isValidType = isSupportedFileType(file)
  const isValidExtension = file.name.endsWith('.csv') || file.name.endsWith('.xls') || file.name.endsWith('.xlsx')
  if (!isValidType || !isValidExtension) {
    notify.error(`Invalid file type: ${file.name}`)
  }
  return isValidType && isValidExtension
}

export default isValidFileType
