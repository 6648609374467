import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from '@/axiosInstance'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useOrgStore = defineStore('org', () => {
  // a reactive state
  const org = ref({})
  const groups = ref([])
  const connections = ref([])

  function setOrg(newOrg) {
    org.value = newOrg
  }

  async function getOrg() {
    try {
      const response = await axios.get('/api/org')
      setOrg(response.data)
      return true
    } catch (error) {
      console.log(error)
    }
  }

  async function getGroups() {
    try {
      const response = await axios.get('/api/get_groups')
      groups.value = response.data
      return true
    } catch (error) {
      console.log(error)
    }
  }

  async function getConnections(forceReload = false) {
    try {
      if (connections.value.length > 0 && !forceReload) {
        return true
      }
      const response = await axios.get('/api/connections')
      connections.value = response.data

      return true
    } catch (error) {
      console.log(error)
    }
  }

  async function getOrganizationFromEmail(email) {
    try {
      const response = await axios.get(`/api/org_from_email`, {
        params: {
          email: email,
        },
      })
      return response.data
    } catch (error) {
      console.log(error)
    }

    return {}
  }

  return {
    org,
    groups,
    connections,
    getOrg,
    getGroups,
    getConnections,
    getOrganizationFromEmail,
  }
})
