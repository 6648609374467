<template>
  <div data-dial-init class="fixed bottom-6 end-6 group z-50 hidden md:block">
    <div
      id="speed-dial-menu-dropdown"
      class="flex-col justify-end hidden py-1 mb-4 space-y-2 bg-white rounded-xl shadow-md dark:bg-gray-900"
    >
      <ul class="text-sm text-gray-500 dark:text-gray-300">
        <li>
          <a
            href="https://docs.getdot.ai/"
            target="_blank"
            class="flex items-center text-left px-5 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 me-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span class="text-sm font-medium">Docs</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="vue_window.$chatwoot.toggle('open')"
            class="flex items-center text-left px-5 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 me-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
              />
            </svg>
            <span class="text-sm font-medium">Support</span>
          </a>
        </li>
        <li class="px-5 py-2 text-xs">
          Made with 🧡 by
          <a href="https://sled.so" class="hover:underline" target="_blank">Sled</a>
        </li>
      </ul>
    </div>
    <button
      type="button"
      data-dial-toggle="speed-dial-menu-dropdown"
      aria-controls="speed-dial-menu-dropdown"
      aria-expanded="false"
      class="flex items-center justify-center ml-auto text-black dark:text-white rounded-full w-8 h-8 hover:bg-gray-100 dark:hover:bg-gray-900 focus:ring-4 focus:ring-gray-50 focus:outline-none dark:focus:ring-gray-950"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
        />
      </svg>

      <span class="sr-only">Open actions menu</span>
    </button>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { initFlowbite } from 'flowbite'

  // initialize components based on data attribute selectors
  onMounted(() => {
    initFlowbite()
  })

  const vue_window = window
</script>
