function getTextAreaRows(input, defaultRows = 2, maxRows = 10) {
  try {
    if (!input || typeof input !== 'string') {
      return defaultRows
    }

    const linebreaks = (input.match(/\n/g) || []).length + 1
    let character_multiple = input.length > 60 ? 2 : 1
    if (input.length > 80) {
      character_multiple = Math.floor(input.length / 80) + 1
    }
    return Math.min(Math.max(linebreaks, character_multiple), maxRows)
  } catch (error) {
    console.error('Error in getTextAreaRows: ', error)
    return defaultRows
  }
}

export default getTextAreaRows
